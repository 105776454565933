import {useTranslation} from "react-i18next";
import TextField from "@material-ui/core/TextField";
import React, {useCallback, useMemo, useState} from "react";


const Field = ({name, label, values, onChange, ...props}) => {
    const { t } = useTranslation();

    return (
        <TextField
            autoFocus
            margin="dense"
            label={label}
            name={name}
            fullWidth
            value={values[name]}
            onChange={onChange}
            {...props}
        />
    );
}
export default function useFieldSet(defaultValues, required) {
    const [values, setValues] = useState(defaultValues);

    const setFieldValue = useCallback(ev => {
        ev.persist();
        setValues(values => ({
            ...values,
            [ev.target.name]: ev.target.type === 'checkbox' ? ev.target.checked : ev.target.value,
        }));
    }, []);

    const FieldComponent = useMemo(() =>
            <Field
                onChange={setFieldValue}
                values={values}
            />
        , [values, setFieldValue]);

    const renderField = useCallback((name, label, extraProps={}) =>
            React.cloneElement(FieldComponent, {name, label, ...extraProps}),
        [FieldComponent]
    );

    return {
        values,
        setFieldValue,
        renderField,
    };
}