import React, {useEffect, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import {measureLabelsLocalized} from "../../constants";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
    tableHeader: {
        '& tr:nth-child(2) th': {
            top: 37,
        },
    },
}));

export default function MeasurementSettingsDialog({component, currentSettings, onSave}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [settings, setSettings] = useState({});
    const { t, i18n: {language} } = useTranslation();

    useEffect(() => {
        setSettings(currentSettings);
    }, [currentSettings]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const updateSettings = (ev) => {
        const [name, key] = ev.target.name.split('.');
        const value = ev.target.value !== '' && ev.target.value !== '-' ? Number(ev.target.value) : ev.target.value;
        setSettings(settings => ({...settings, [name]: {...settings[name], [key]: value}}));
    };

    return (
        <>
            {React.cloneElement(component, {
                onClick: handleClickOpen,
            })}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullScreen>
                <DialogTitle id="form-dialog-title">{t('MeasurementSettingsDialog.title')}</DialogTitle>
                <DialogContent style={{padding: 0}}>
                    <Table stickyHeader className={classes.table} size="small" aria-label="sessions">
                        <TableHead className={classes.tableHeader}>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align='center' colSpan={2}>{t('MeasurementSettingsDialog.viewable')}</TableCell>
                                <TableCell align='center' colSpan={2}>{t('MeasurementSettingsDialog.valid')}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width={240}>{t('MeasurementSettingsDialog.description')}</TableCell>
                                <TableCell>MIN</TableCell>
                                <TableCell>MAX</TableCell>
                                <TableCell>MIN</TableCell>
                                <TableCell>MAX</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(settings).map(([name, values]) => {
                                return (
                                    <TableRow key={name}>
                                        <TableCell>
                                            {measureLabelsLocalized[language][name]}
                                        </TableCell>
                                        {['viewMin', 'viewMax', 'validMin', 'validMax'].map(key => {
                                            const fieldName = `${name}.${key}`;
                                            return (
                                                <TableCell
                                                    key={fieldName}
                                                >
                                                    <TextField
                                                        type="number"
                                                        autoFocus
                                                        margin="dense"
                                                        name={fieldName}
                                                        fullWidth
                                                        value={values[key]}
                                                        onChange={updateSettings}
                                                    />
                                                </TableCell>

                                            );
                                        })}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions className={classes.buttons}>
                    <Button onClick={handleClose} color="primary">
                        {t('MeasurementSettingsDialog.cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            onSave(settings);
                            handleClose();
                        }}
                        color="primary"
                        variant="contained"
                    >
                        {t('MeasurementSettingsDialog.save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}