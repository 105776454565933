import {withStyles} from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

export const ExpansionPanel = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {},
        '&$expanded': {
            '&:before': {
                opacity: 1,
            },
            margin: 0,
        },
    },
    expanded: {},
})(MuiExpansionPanel);
export const ExpansionPanelSummary = withStyles({
    root: {
        minHeight: 40,
        '&$expanded': {
            minHeight: 40,
        },
        '&::before': {
            opacity: 1,
        },
    },
    content: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '8px 0',
        '&$expanded': {
            margin: '8px 0',
        },
    },
    expandIcon: {
        padding: '8px',
    },
    expanded: {},
})(MuiExpansionPanelSummary);
export const ExpansionPanelDetails = withStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
    },
}))(MuiExpansionPanelDetails);