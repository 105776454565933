import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 24 24" fill="inherit" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.7426 21.6187C22.8577 21.5157 22.9417 21.3708 22.9764 21.2046C23.0078 21.0713 23.0079 20.9298 22.9766 20.7964C22.9421 20.6295 22.8578 20.484 22.7421 20.3807L20.9043 18.2239C20.6499 17.9254 20.2374 17.9254 19.9829 18.2239L19.9622 18.2483C19.7078 18.5468 19.7078 19.0309 19.9622 19.3295L20.7195 20.2182L15.6667 20.2182C15.2985 20.2182 15 20.553 15 20.9661L15 21.0338C15 21.4468 15.2985 21.7817 15.6667 21.7817L20.7196 21.7817L19.9622 22.6705C19.7078 22.9691 19.7078 23.4532 19.9622 23.7517L19.9829 23.7761C20.2374 24.0746 20.6499 24.0746 20.9043 23.7761L22.7426 21.6187Z" />
            <path d="M17.9998 9.0172C17.9998 16.2006 10.1697 22.8533 10.1697 22.8533C9.71215 23.2421 8.96339 23.2421 8.50587 22.8533C8.50587 22.8533 0.675781 16.2006 0.675781 9.0172C0.675868 4.06268 4.55395 0.0462646 9.33779 0.0462646C14.1216 0.0462646 17.9998 4.06268 17.9998 9.0172Z" />
            <path d="M7.41264 7.06403C6.8811 7.06403 6.4502 7.45902 6.4502 7.94627V12.3575C6.4502 12.8447 6.8811 13.2397 7.41264 13.2397C7.94418 13.2397 8.37508 12.8447 8.37508 12.3575V7.94627C8.37508 7.45902 7.94418 7.06403 7.41264 7.06403Z" fill="white"/>
            <path d="M11.2624 7.06403C10.7309 7.06403 10.3 7.45902 10.3 7.94627V12.3575C10.3 12.8447 10.7309 13.2397 11.2624 13.2397C11.794 13.2397 12.2249 12.8447 12.2249 12.3575V7.94627C12.2249 7.45902 11.794 7.06403 11.2624 7.06403Z" fill="white"/>
        </svg>
    </SvgIcon>
)