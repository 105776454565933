import React, {useCallback, useMemo, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles(theme => ({
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
}));


const Field = ({name, label, values, onChange, ...props}) => {
    const { t } = useTranslation();

    return (
        <TextField
            autoFocus
            margin="dense"
            label={label}
            name={name}
            fullWidth
            value={values[name]}
            onChange={onChange}
            {...props}
        />
    );
}

const defaultReportData = process.env.NODE_ENV === 'development' ?
    {
        email: '',
        sendEmail: true,
        results: '',
    } : {
        email: '',
        sendEmail: true,
        results: '',
    };

export default function GenerateReportDialog({component, onSave, defaultValues}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState(defaultValues || defaultReportData);
    const { t } = useTranslation();

    const handleClickOpen = () => {
        setOpen(true);
        setValues(defaultReportData);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const setFieldValue = useCallback(ev => {
        ev.persist();
        setValues(values => ({
            ...values,
            [ev.target.name]: ev.target.type === 'checkbox' ? ev.target.checked : ev.target.value,
        }));
    }, []);

    const FieldComponent = useMemo(() =>
        <Field
            onChange={setFieldValue}
            values={values}
        />
    , [values, setFieldValue]);

    const renderField = useCallback((name, label, extraProps={}) =>
        React.cloneElement(FieldComponent, {name, label, ...extraProps}),
        [FieldComponent]
    );

    function validateEmail(value)
    {
        return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value))
    }

    const validEmail = validateEmail(values.email);

    return (
        <>
            {React.cloneElement(component, {
                onClick: handleClickOpen,
            })}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullScreen>
                <DialogTitle id="form-dialog-title">{t('GenerateReportDialog.title')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                margin="dense"
                                label={t('GenerateReportDialog.input-email')}
                                name='email'
                                fullWidth
                                value={values.email}
                                onChange={setFieldValue}
                                error={!validEmail}
                                helperText={validEmail ? '' : t('GenerateReportDialog.invalid-email')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                margin="dense"
                                label={t('GenerateReportDialog.results')}
                                name='results'
                                fullWidth
                                multiline
                                rows={8}
                                value={values.results}
                                onChange={setFieldValue}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.buttons}>
                    <Button onClick={handleClose} color="primary">
                        {t('GenerateReportDialog.cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            onSave(values);
                            handleClose();
                        }}
                        color="primary"
                        variant="contained"
                        disabled={values.email === ''}
                    >
                        {t('GenerateReportDialog.ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}