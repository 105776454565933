import React, {useEffect, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DeleteDialog from "../common/DeleteDialog";
import {useTranslation} from "react-i18next";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    addButton: {
        margin: -12,
    },
}));


export default function AssignProtocolDialog({component, postMessage, onSelect, enableCustomName=false}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [protocols, setProtocols] = useState([]);
    const [customName, setCustomName] = useState(null);

    const { t } = useTranslation();

    // useEffect(() => {
    //     setProtocols(currentProtocols.map(protocol => ({
    //         id: protocol.id,
    //         nombre: protocol.nombre,
    //     })));
    // }, [currentProtocols]);

    useEffect(() => {
        if (!open)
            return;

        postMessage({
            type: 'GET_PROTOCOLS',
            data: undefined,
            responseType: 'protocols',
            responseCallback: function (currentProtocols) {
                setProtocols(currentProtocols.map(protocol => ({
                    id: protocol.id,
                    nombre: protocol.nombre,
                })));
            },
        });
    }, [open, postMessage]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelectProtocol = protocolId => () => {
        onSelect({protocolId});
        handleClose();
    };

    const handleSaveCustomName = () => {
        onSelect({customName});
        handleClose();
    };

    const showCustomName = enableCustomName && customName !== null;

    return (
        <>
            {React.cloneElement(component, {
                onClick: handleClickOpen,
            })}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" className={classes.title}>
                    {t('AssignProtocolDialog.title')}
                </DialogTitle>
                <DialogContent>
                    {showCustomName ?
                        <>
                            <TextField
                                value={customName}
                                onChange={ev => setCustomName(ev.target.value)}
                                margin="dense"
                            />
                        </>
                        :
                        <>
                            <Typography>{t('AssignProtocolDialog.description')}</Typography>
                            <List>
                                {protocols.map(protocol => (
                                    <ListItem
                                        key={protocol.id}
                                        button
                                        onClick={handleSelectProtocol(protocol.id)}
                                    >
                                        {protocol.nombre}
                                    </ListItem>
                                ))}
                                <ListItem
                                    button
                                    onClick={handleSelectProtocol(null)}
                                >
                                    {t('AssignProtocolDialog.no-protocol')}
                                </ListItem>
                                {enableCustomName &&
                                <ListItem
                                    button
                                    onClick={() => setCustomName('')}
                                >
                                    {t('AssignProtocolDialog.custom-name')}
                                </ListItem>}
                            </List>
                        </>
                    }
                </DialogContent>
                <DialogActions className={classes.buttons}>
                    <Button onClick={handleClose} style={{marginRight: 'auto'}}>
                        {t('AssignProtocolDialog.cancel')}
                    </Button>
                    {showCustomName &&
                    <Button onClick={handleSaveCustomName} color="primary" style={{marginRight: 'auto'}}>
                        {t('AssignProtocolDialog.save')}
                    </Button>}
                </DialogActions>
            </Dialog>
        </>
    );
}