import React, {useCallback, useEffect, useMemo, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DeleteDialog from "../common/DeleteDialog";
import {useTranslation} from "react-i18next";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {FormControl, FormLabel, Input, Radio, RadioGroup, Slider} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: `${theme.spacing(3)}px`,
        margin: theme.spacing(2),
        fontSize: 18,
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    addButton: {
        margin: -12,
    },
    heightContainer: {
        display: 'flex',
        padding: theme.spacing(1),
    },
    heightLabel: {
        marginLeft: theme.spacing(2),
        whiteSpace: 'nowrap',
        paddingTop: 12,
    },
}));


export default function ChangeActorPropsDialog({component, onOpen, onSave, currentActorProps, useImperial}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [actorProps, setActorProps] = useState(null);

    useEffect(() => {
        if (currentActorProps === null)
            return;

        setActorProps(currentActorProps);
    }, [currentActorProps])

    const { t } = useTranslation();

    const handleClickOpen = () => {
        setOpen(true);
        onOpen();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onChange = ev => {
        ev.persist();
        setActorProps(props => ({
            ...props,
            [ev.target.name]: ev.target.value,
        }));
    };

    const setHeight = value => setActorProps(props => ({...props, height: value}));

    const handleHeightSliderChange = (event, newValue) => setHeight(newValue);

    const MIN_HEIGHT = 0.90;
    const MAX_HEIGHT = 2.20;

    const getLabelForHeight = useCallback(value => {
        if (useImperial) {
            const foots = value * 3.281;
            const roundedFoots = Math.floor(foots);
            const inches = (foots - roundedFoots) * 12;
            let roundedInches = Math.floor(inches);
            const quarterInches = Math.round((inches - roundedInches) * 4);
            if (quarterInches === 4)
                roundedInches += 1;

            const quarterSymbols = ['', '¼', '½', '¾', ''];

            return `${roundedFoots} ft ${roundedInches}${quarterSymbols[quarterInches]} in`;
        }
        return `${value.toFixed(2)} m`
    }, [useImperial]);

    const heightMarks = useMemo(() => {
        const heightsForMarks = [1.20, 1.35, 1.50, 1.65, 1.80, 1.95];
        return heightsForMarks.map(value => {
            const label = getLabelForHeight(value);
            return { value, label };
        });
    }, [getLabelForHeight]);

    const actorHeight = actorProps ? getLabelForHeight(actorProps.height) : null;

    return (
        <>
            {React.cloneElement(component, {
                onClick: handleClickOpen,
            })}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="lg">
                <DialogTitle id="form-dialog-title" className={classes.title}>
                    {t('ChangeActorPropsDialog.title')}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} style={{marginTop: 12}}>
                        {actorProps === null ?
                            <Grid item xs={12} className={classes.loading}>
                                <CircularProgress size={32} />
                                {t('ChangeActorPropsDialog.loading')}
                            </Grid>
                            :
                            <>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">{t('ChangeActorPropsDialog.gender')}</FormLabel>
                                        <RadioGroup
                                            row
                                            name="gender"
                                            onChange={onChange}
                                            value={actorProps.gender}
                                        >
                                            <FormControlLabel
                                                value="male"
                                                control={<Radio color="primary" />}
                                                label={t('ChangeActorPropsDialog.male')}
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="female"
                                                control={<Radio color="primary" />}
                                                label={t('ChangeActorPropsDialog.female')}
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset" fullWidth>
                                        <FormLabel id="height-slider" component="legend">{t('ChangeActorPropsDialog.height')}</FormLabel>
                                        <div className={classes.heightContainer}>
                                            <Slider
                                                value={typeof actorProps.height === 'number' ? actorProps.height : 0}
                                                onChange={handleHeightSliderChange}
                                                name='height'
                                                aria-labelledby="height-slider"
                                                min={MIN_HEIGHT}
                                                max={MAX_HEIGHT}
                                                marks={heightMarks}
                                                step={0.01}
                                            />
                                            <span
                                                className={classes.heightLabel}
                                            >
                                        {actorHeight}
                                    </span>
                                        </div>
                                    </FormControl>
                                </Grid>
                            </>}
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.buttons}>
                    <Button onClick={handleClose}>
                        {t('ChangeActorPropsDialog.cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            onSave(actorProps);
                            handleClose();
                        }}
                        color="primary"
                    >
                        {t('ChangeActorPropsDialog.save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}