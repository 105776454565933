import React, {useCallback, useEffect, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import debounce from "@material-ui/core/utils/debounce";
import SesionesList from "./SesionesList";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
}));

export default function NewSessionDialog({component, onSave, postMessage}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [pacienteOptions, setPacienteOptions] = useState(null);
    const [filteredPacienteOptions, setFilteredPacienteOptions] = useState(null);
    const [name, setName] = useState('');
    const { t } = useTranslation();
    const [selectedItem, setSelectedItem] = useState(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setPacienteOptions(null);
    };

    useEffect(() => {
        setFilteredPacienteOptions(pacienteOptions)
    }, [pacienteOptions]);

    useEffect(() => {
        if (!open)
            return;

        postMessage({
            type: 'GET_PACIENTE_OPTIONS',
            data: undefined,
            responseType: 'paciente-options',
            responseCallback: setPacienteOptions,
        });
    }, [open, postMessage]);

    const debouncedFilterSesiones = useCallback(
        debounce(
            (value) => {
                if (pacienteOptions === null)
                    return;

                setFilteredPacienteOptions(
                    value !== '' ?
                        pacienteOptions.filter(paciente => paciente.nombre.toLowerCase().includes(value.toLowerCase()))
                        :
                        pacienteOptions
                );
            },
            500
        ),
        [pacienteOptions]);

    const changeName = useCallback(function(value) {
        setName(value);
        debouncedFilterSesiones(value);
    }, [debouncedFilterSesiones]);

    const onSelectItem = useCallback(index => {
        setSelectedItem(filteredPacienteOptions[index]);
        changeName(filteredPacienteOptions[index].nombre);
    }, [filteredPacienteOptions, changeName]);

    return (
        <>
            {React.cloneElement(component, {
                onClick: handleClickOpen,
            })}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullScreen>
                <DialogTitle id="form-dialog-title">{t('NewSessionDialog.title')}</DialogTitle>
                <DialogContent style={{display: 'flex', flexDirection: 'column'}}>
                    <DialogContentText>
                        {t('NewSessionDialog.text')}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label={t('NewSessionDialog.input-paciente')}
                        fullWidth
                        value={name}
                        onChange={ev => {
                            changeName(ev.target.value);
                            setSelectedItem(null);
                        }}
                        helperText={t('NewSessionDialog.input-paciente-helper')}
                    />
                    {selectedItem === null ?
                        <>
                            <DialogContentText style={{marginTop: 24}}>
                                {t('NewSessionDialog.choose-existing-patient')}:
                            </DialogContentText>
                            <div
                                style={{flex: 1}}
                            >
                                {filteredPacienteOptions !== null &&
                                <SesionesList items={filteredPacienteOptions} onSelect={onSelectItem} />}
                            </div>
                        </>
                        :
                        <div style={{display: 'flex', alignItems: 'center', marginTop: 24}}>
                            <Typography>{t('NewSessionDialog.create-followup')}</Typography>
                            <Button
                                color='primary'
                                onClick={() => {
                                    changeName('');
                                    setSelectedItem(null);
                                }}
                            >
                                {t('NewSessionDialog.cancel')}
                            </Button>
                        </div>
                    }
                </DialogContent>
                <DialogActions className={classes.buttons}>
                    <Button onClick={handleClose} color="primary">
                        {t('NewSessionDialog.cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            const item = selectedItem || {
                                pacienteId: null,
                                nombre: name,
                            };
                            onSave(item);
                            handleClose();
                        }}
                        color="primary"
                        variant="contained"
                    >
                        {t('NewSessionDialog.ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}