import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 24 24" fill="inherit" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.257359 21.6187C0.14227 21.5157 0.0582623 21.3708 0.0236484 21.2046C-0.0077999 21.0713 -0.00788274 20.9298 0.0233998 20.7964C0.0579092 20.6295 0.142239 20.484 0.257864 20.3807L2.09571 18.2239C2.35014 17.9254 2.76264 17.9254 3.01706 18.2239L3.03779 18.2483C3.29222 18.5468 3.29222 19.0309 3.03779 19.3295L2.28052 20.2182L7.33333 20.2182C7.70152 20.2182 8 20.553 8 20.9661L8 21.0338C8 21.4468 7.70152 21.7817 7.33333 21.7817L2.2804 21.7817L3.03779 22.6705C3.29222 22.9691 3.29222 23.4532 3.03779 23.7517L3.01706 23.7761C2.76264 24.0746 2.35014 24.0746 2.09571 23.7761L0.257359 21.6187Z" />
            <path d="M21.9998 9.0172C21.9998 16.2006 14.1697 22.8533 14.1697 22.8533C13.7122 23.2421 12.9634 23.2421 12.5059 22.8533C12.5059 22.8533 4.67578 16.2006 4.67578 9.0172C4.67587 4.06268 8.55395 0.0462646 13.3378 0.0462646C18.1216 0.0462646 21.9998 4.06268 21.9998 9.0172Z" />
            <path d="M11.4126 7.06403C10.8811 7.06403 10.4502 7.45902 10.4502 7.94627V12.3575C10.4502 12.8447 10.8811 13.2397 11.4126 13.2397C11.9442 13.2397 12.3751 12.8447 12.3751 12.3575V7.94627C12.3751 7.45902 11.9442 7.06403 11.4126 7.06403Z" fill="white"/>
            <path d="M15.2624 7.06403C14.7309 7.06403 14.3 7.45902 14.3 7.94627V12.3575C14.3 12.8447 14.7309 13.2397 15.2624 13.2397C15.794 13.2397 16.2249 12.8447 16.2249 12.3575V7.94627C16.2249 7.45902 15.794 7.06403 15.2624 7.06403Z" fill="white"/>
        </svg>
    </SvgIcon>
)
