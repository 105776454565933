import React, {useEffect, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DeleteDialog from "../common/DeleteDialog";
import {useTranslation} from "react-i18next";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles(theme => ({
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    addButton: {
        margin: -12,
    },
}));

const editableFields = ['velocidad', 'tiempoTotal', 'tiempoCalentamiento', 'tiempoGrabacion', 'guardarDat', 'guardarHuellas', 'guardarVideo', 'guardarLateral'];


export default function ProtocolSettingsDialog({component, currentProtocols, onSave, onRestoreDefaults}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [protocols, setProtocols] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        setProtocols(currentProtocols.map(protocol => ({
            id: protocol.id,
            nombre: protocol.nombre,
            ...editableFields.reduce((a, e) => (a[e] = protocol[e], a), {}),
        })));
    }, [currentProtocols]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const updateProtocol = (idx) => (ev) => {
        const key = ev.target.name;

        let value = ev.target.value;

        if (ev.target.type === 'checkbox')
            value = ev.target.checked;
        else if (ev.target.type === 'number' && ev.target.value !== '' && ev.target.value !== '-')
            value = Number(ev.target.value);

        setProtocols(protocols => ([
            ...protocols.slice(0, idx),
            {
                ...protocols[idx],
                [key]: value,
            },
            ...protocols.slice(idx + 1),
        ]));
    };

    const addProtocol = () => {
        setProtocols(protocols => {
            const newProtocol = {
                id: Math.max(...protocols.map(p => p.id)) + 1,
                nombre: '',
            };


            editableFields.forEach(key => {
                const isBool = typeof(protocols[0][key]) === "boolean";
                newProtocol[key] = isBool ? false : 0.0;
            });

            return [
                ...protocols,
                newProtocol,
            ];
        });
    };

    const deleteProtocol = i => {
        setProtocols(protocols => ([
            ...protocols.slice(0, i),
            ...protocols.slice(i + 1),
        ]));
    }

    console.log(protocols)

    return (
        <>
            {React.cloneElement(component, {
                onClick: handleClickOpen,
            })}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullScreen>
                <DialogTitle id="form-dialog-title" className={classes.title} disableTypography>
                    <Typography variant='h6'>{t('ProtocolSettingsDialog.title')}</Typography>
                    <IconButton
                        onClick={addProtocol}
                        className={classes.addButton}
                    >
                        <AddIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Table className={classes.table} size="small" aria-label="sessions">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{minWidth: 190}}>{t('ProtocolSettingsDialog.name')}</TableCell>
                                <TableCell>{t('ProtocolSettingsDialog.speed')} (km/h)</TableCell>
                                <TableCell>{t('ProtocolSettingsDialog.duration')} (s)</TableCell>
                                <TableCell>{t('ProtocolSettingsDialog.warm-up')} (s)</TableCell>
                                <TableCell>{t('ProtocolSettingsDialog.recording')} (s)</TableCell>
                                <TableCell>{t('ProtocolSettingsDialog.save-dat')}</TableCell>
                                <TableCell>{t('ProtocolSettingsDialog.save-huellas')}</TableCell>
                                <TableCell>{t('ProtocolSettingsDialog.save-video')}</TableCell>
                                <TableCell>{t('ProtocolSettingsDialog.save-lateral')}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {protocols.map((protocol, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                name={'nombre'}
                                                fullWidth
                                                value={protocol.nombre}
                                                onChange={updateProtocol(i)}
                                            />
                                        </TableCell>
                                        {editableFields.map(key => {
                                            const isBool = typeof(protocol[key]) == "boolean";

                                            return (
                                                <TableCell
                                                    key={key}
                                                >
                                                    {isBool ?
                                                        <Switch
                                                            name={key}
                                                            checked={protocol[key]}
                                                            onChange={updateProtocol(i)}
                                                        />
                                                        :
                                                        <TextField
                                                            autoFocus
                                                            type='number'
                                                            margin="dense"
                                                            name={key}
                                                            fullWidth
                                                            value={protocol[key]}
                                                            onChange={updateProtocol(i)}
                                                        />}
                                                </TableCell>

                                            );
                                        })}
                                        <TableCell>
                                            <DeleteDialog
                                                component={
                                                    <IconButton size='small'>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                }
                                                title={t('ProtocolSettingsDialog.delete-protocol')}
                                                onDelete={() => deleteProtocol(i)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions className={classes.buttons}>
                    <Button onClick={handleClose} color="primary" style={{marginRight: 'auto'}}>
                        {t('ProtocolSettingsDialog.close')}
                    </Button>
                    <DeleteDialog
                        component={
                            <Button
                                variant="contained"
                            >
                                {t('ProtocolSettingsDialog.restore-factory-defaults')}
                            </Button>
                        }
                        title={t('ProtocolSettingsDialog.restore-factory-defaults')}
                        onDelete={onRestoreDefaults}
                        okLabel={t('ProtocolSettingsDialog.restore')}
                    />
                    <Button
                        onClick={() => {
                            onSave(protocols);
                            handleClose();
                        }}
                        color="primary"
                        variant="contained"
                    >
                        {t('ProtocolSettingsDialog.save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}