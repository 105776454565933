import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    root: {
        fontSize: 30,
        fontWeight: 300,
        '& span': {
            fontSize: '67%',
        },
        marginLeft: 45,
        marginRight: 20,
    },
}), {name: 'Timer'});

export function splitMillisInParts(millis) {
    const absMillis = Math.abs(millis);

    return {
        isPast: millis < 0,
        hours: Math.floor((absMillis / (1000 * 60 * 60))),
        minutes: Math.floor((absMillis / 1000 / 60) % 60),
        seconds: Math.floor((absMillis / 1000) % 60),
        millis: Math.floor(absMillis % 1000),
    };
}

function calculateTimeLeft(startTime, endTime) {
    const difference = startTime ? (endTime || new Date()) - startTime : 0;

    return splitMillisInParts(difference);
}

export function fmtNum(num, n) {
    return num.toString().padStart(n, '0')
}
export default function Timer({startTime, endTime}) {
    const classes = useStyles();
    const [elapsedTime, setElapsedTime] = useState(calculateTimeLeft(startTime, endTime));

    useEffect(() => {
        if (endTime) {
            setElapsedTime(calculateTimeLeft(startTime, endTime));
            return;
        }

        const interval = setInterval(() => {
            setElapsedTime(calculateTimeLeft(startTime));
        });
        return () => clearInterval(interval);
    }, [startTime, endTime]);

    const {isPast, hours, minutes, seconds, millis} = elapsedTime;

    return (
        <div
            className={classes.root}
        >
            {isPast ? '-' : ''}{fmtNum(hours, 2)}:{fmtNum(minutes, 2)}:{fmtNum(seconds, 2)}<span>.{fmtNum(millis, 3)}</span>
        </div>
    );
}