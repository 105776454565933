import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => (
    <SvgIcon {...props}>
        <path d="M14.8344 2.20553C13.6452 2.61245 13.0091 3.91037 13.4161 5.09953C13.823 6.2887 15.1209 6.9248 16.3101 6.51788C17.4992 6.11095 18.1353 4.81304 17.7284 3.62387C17.3215 2.43471 16.0236 1.7986 14.8344 2.20553Z" fill="currentColor"/>
        <path d="M10.366 6.74418C7.32813 7.78373 6.18366 10.3594 7.30428 13.6342C10.2706 22.3026 12.709 22.9636 14.8494 22.2312C16.407 21.6981 16.6786 19.9122 15.6559 16.9238C15.1361 15.4046 15.3853 14.1843 15.7011 13.0448C16.0103 11.9294 16.33 10.7756 15.826 9.30269C14.7556 6.17472 11.8905 6.22251 10.366 6.74418Z" fill="currentColor"/>
        <path d="M11.352 5.28726C11.9464 5.08389 12.2646 4.43457 12.0612 3.84026C11.8579 3.24594 11.2085 2.92771 10.6142 3.13108C10.0199 3.33446 9.70168 3.98377 9.90505 4.57809C10.1084 5.1724 10.7577 5.49064 11.352 5.28726Z" fill="currentColor"/>
        <path d="M8.54639 6.24331C9.1407 6.03994 9.45893 5.39062 9.25556 4.79631C9.05219 4.202 8.40287 3.88377 7.80856 4.08714C7.21424 4.29051 6.89601 4.93983 7.09938 5.53414C7.30275 6.12845 7.95207 6.44668 8.54639 6.24331Z" fill="currentColor"/>
        <path d="M6.94599 6.92131C6.74262 6.327 6.0933 6.00877 5.49899 6.21214C4.90467 6.41551 4.58644 7.06483 4.78981 7.65914C4.99318 8.25345 5.6425 8.57168 6.23682 8.36831C6.83113 8.16494 7.14938 7.51566 6.94599 6.92131Z" fill="currentColor"/>
        <path d="M4.28121 9.22288C3.6869 9.42625 3.36867 10.0756 3.57204 10.6699C3.77541 11.2642 4.42473 11.5824 5.01904 11.3791C5.61336 11.1757 5.93158 10.5264 5.72821 9.93205C5.52484 9.33774 4.87553 9.01951 4.28121 9.22288Z" fill="currentColor"/>
    </SvgIcon>
)