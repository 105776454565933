import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import {useTranslation} from "react-i18next";
import Moment from "react-moment";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 400,
        maxWidth: 300,
        backgroundColor: theme.palette.background.paper,
    },
}));

function renderRow({index, style, data}) {

    const {
        items,
        onClick,
        dateFormat,
    } = data;

    const item = items[index];
    const nombre = item.nombre;
    const fecha = item.fecha;

    return (
        <ListItem button style={style} key={index} onClick={() => onClick(index)}>
            <ListItemText
                primary={nombre}
                secondary={fecha && <Moment format={dateFormat}>{fecha}</Moment>}
            />
        </ListItem>
    );
}

renderRow.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
};

export default function SesionesList({items, onSelect}) {
    const { i18n: {language} } = useTranslation();

    const data = useMemo(() => ({
        items: items,
        onClick: onSelect,
        dateFormat: language === 'en' ? 'MM-DD-YYYY | HH:mm' : 'DD-MM-YYYY | HH:mm',
    }), [items, onSelect, language]);

    const size = items && items.length > 0 && items[0].fecha ? 56 : 40;

    return (
        <AutoSizer>
            {({height, width}) =>
                <FixedSizeList
                    height={height}
                    width={width}
                    itemSize={size}
                    itemCount={items.length}
                    itemData={data}
                >
                    {renderRow}
                </FixedSizeList>}
        </AutoSizer>
    );
}
