import React, {PureComponent, useState} from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import ErrorDialogContext from './ErrorDialogContext'
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles(theme => ({
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
}));

function ErrorDialog({message, open, onClose}) {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Error</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t(message)}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.buttons}>
                <Button onClick={handleClose} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}




export default class ErrorDialogProvider extends PureComponent {
    state = {
        message: null,
        open: false
    }

    constructor (props) {
        super(props)
        this.contextValue = {
            showMessage: this.showMessage
        }
    }

    /**
     * Display a message with this errorDialog.
     * @param {string} message message to display
     * @public
     */
    showMessage = (message) => {
        this.setState({ open: true, message })
    }

    handleCloseClick = () => {
        this.setState({ open: false, message: null })
    }

    render () {
        const {
            message,
            open,
        } = this.state

        const {
            children,
        } = this.props

        return (
            <>
                <ErrorDialogContext.Provider
                    value={this.contextValue}
                >
                    {children}
                </ErrorDialogContext.Provider>
                <ErrorDialog
                    open={open}
                    message={message}
                    onClose={this.handleCloseClick}
                />
            </>
        )
    }
}

ErrorDialogProvider.propTypes = {
    /**
     * The children that are wrapped by this provider.
     */
    children: PropTypes.node,
}