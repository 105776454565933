import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {SERVER_URL} from "../../App";
import {useHistory, useParams} from 'react-router-dom';
import Timer, {fmtNum, splitMillisInParts} from "./Timer";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {blockedAngles, measureKeys, measureLabels, measureLabelsLocalized} from "../../constants";
import MeasurementSettingsDialog from "../common/MeasurementSettingsDialog";
import SettingsIcon from "@material-ui/icons/Settings";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneIcon from '@material-ui/icons/Done';
import TableContainer from "@material-ui/core/TableContainer";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import RecordIcon from "../../icons/RecordIcon";
import Paper from "@material-ui/core/Paper";
// import Websocket from "react-websocket";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MuiTableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import StopIcon from '@material-ui/icons/Stop';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SpeedIcon from '@material-ui/icons/Speed';
import TimerIcon from "../../icons/TimerIcon";
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary} from "./ExpansionPanel";
import Websocket from "../Websocket";
import ProtocolSettingsDialog from "./ProtocolSettingsDialog";
import {useTranslation} from "react-i18next";
import AssignProtocolDialog from "./AssignProtocolDialog";
import ChangeActorPropsDialog from "./ChangeActorPropsDialog";
import {useSnackbar} from "material-ui-snackbar-provider";
import useErrorDialog from "../ErrorDialogProvider/useErrorDialog";


const TableCell = ({children, ...props}) => <MuiTableCell style={{padding: 6, fontSize: 11, height: 39}} {...props}>{children}</MuiTableCell>


const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        display: 'flex',
        height: 'calc(100% - 65px)',
        justifyContent: 'center',
        padding: theme.spacing(2),
        gap: `${theme.spacing(2)}px`,
    },
    paper: {
        // padding: theme.spacing(2),
        // margin: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: 600,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    progressOverlay: {
        position: 'absolute',
        top: 64,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgb(255,255,255,0.8)',
    },
    controls: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    measurementsHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: theme.spacing(2),
        position: 'relative',
    },
    speedContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        // width: '85%',
        alignItems: 'center',
        margin: theme.spacing(3, 2),
    },
    timerContainer: {
        display: 'flex',
        // justifyContent: 'space-between',
        // width: '85%',
        alignItems: 'center',
        margin: theme.spacing(1, 2),
        position: 'relative',
    },
    protocolTimer: {
        margin: theme.spacing(2, 0),
    },
    labelWithIcon: {
        fontSize: 11,
        color: '#686868',
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            width: 18,
        },
        '& span': {
            marginLeft: theme.spacing(0.5),
        },
    },
    protocolLabels: {
        width: 135,
        display: 'flex',
        justifyContent: 'space-between',
    },
    title: {
        textAlign: 'left',
        marginBottom: theme.spacing(2),
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '100%',
    },
    recordButton: {
        color: '#ea2554',
    },
    playButton: {
        color: '#2CDA91',
    },
    stopButton: {

    },
    resetButton: {

    },
    saveButton: {

    },
    recordingPanelHeader: {
        // width: '100%',
        // position: 'relative',
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        margin: theme.spacing(2),
        position: 'relative',
    },
    settingsButton: {
        position: 'absolute',
        right: 0,
    },
    actorPropsButton: {
        position: 'absolute',
        left: 0,
    },
    tabButton: {
        fontSize: 11,
        fontWeight: 500,
        color: '#212121',
        width: 100,
    },
    tabButtonActive: {
        backgroundColor: '#21b7e9',
        color: 'white',
        '&:hover': {
            backgroundColor: '#21b7e9',
        },
    },
    speedButtonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: theme.spacing(2),
        marginBottom: 0,
        // width: '85%',
    },
    speedButton: {
        height: 28,
        color: '#292929',
        fontSize: 9,
    },
    manualProtocolsContainer: {
        display: 'flex',
        // alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        overflowY: 'scroll',
        overflowX: 'hidden',
        flex: 1,
    },
    bottomActions: {
        display: 'flex',
        padding: theme.spacing(2),
        marginTop: 'auto',
        gap: `${theme.spacing(2)}px`,
    },
    addRecording: {
        flex: 1,
        color: 'white',
        backgroundColor: '#21b7e9',
        // margin: theme.spacing(2),
        // width: '50%',
        whiteSpace: 'no-wrap',
        // marginTop: 'auto',
        height: 48,
        '&:hover': {
            backgroundColor: '#21b7e9',
        },
    },
    emergencyStop: {
        flex: 1,
        color: 'white',
        // margin: theme.spacing(2),
        // width: `calc(100% - ${theme.spacing(4)}px)`,
        backgroundColor: '#F44336',
        // marginTop: 'auto',
        height: 48,
        '&:hover': {
            backgroundColor: '#F44336',
        },
    },
    button: {
        border: '1px solid #ccc',
        width: 42,
        height: 42,
        '& svg': {
            fontSize: 20,
        },
    },
    angleAlert: {
        fontSize: 18,
        marginBottom: -4,
    },
    angleWarning: {
        color: '#ffb657',
    },
    angleError: {
        color: '#ea2554',
    },
}), {name: 'RecordingView'});

const defaultProtocolExecution = {
    date: null,
    running: false,
    recordingTimer: null,
    savingStatus: '',
    recordingRoute: null,
};

const defaultManualProtocol = {
    active: false,
    recording: false,
    recordingRoute: null,
    recordingStartDate: null,
    recordingEndDate: null,
    protocolExecution: {...defaultProtocolExecution},
};

export default function RecordingView() {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const errorDialog = useErrorDialog();

    const [state, setState] = useState({
        skeletonEnabled: false,
        kinectEnabled: false,
        enabledAngles: {},
        recording: false,
        isTracking: false,
        savingStatus: '',
        recordingRoute: null,
        treadmillSpeed: 0,
    });
    const [protocols, setProtocols] = useState([]);
    const [selectedProtocol, setSelectedProtocol] = useState(false);
    const [manualProtocols, setManualProtocols] = useState([{...defaultManualProtocol}]);
    const [protocolExecution, setProtocolExecution] = useState({...defaultProtocolExecution});
    const [desiredTreadmillSpeed, setDesiredTreadmillSpeedKmh] = useState(0);
    const [currentActorProps, setCurrentActorProps] = useState(null);

    const { t, i18n: {language} } = useTranslation();

    const handleProtocolChange = (panel) => (event, newExpanded) => {
        setSelectedProtocol(newExpanded ? panel : false);
    };

    const [manualMode, setManualMode] = useState(true);

    const history = useHistory();
    const [angleData, setAngleData] = useState({});

    const {sessionName, recordingName} = useParams();

    const dataWebsocketRef = useRef();
    const sessionWebsocketRef = useRef();

    const {
        recording,
        recordingStartDate,
        recordingEndDate,
        recordingRoute,
        enabledAngles,
        // isTracking,
        savingStatus,
        treadmillSpeed,
        streamingPressures,
    } = state;

    const isTracking = true;

    useEffect(() => {
        if (!selectedProtocol) {
            // setProtocolExecution({...protocol.protocolExecution});
            return;
        }

        const protocol = protocols.find(p => p.id === selectedProtocol);

        setProtocolExecution({...protocol.protocolExecution});
    }, [selectedProtocol]);

    useEffect(() => {
        if (!manualMode)
            return;

        const protocolIndex = manualProtocols.findIndex(p => p.active);

        if (protocolIndex < 0)
            return;

        setManualProtocols(protocols => [
            ...protocols.slice(0, protocolIndex),
            {
                ...protocols[protocolIndex],
                protocolExecution: {...protocolExecution},
            },
            ...protocols.slice(protocolIndex + 1),
        ]);
    }, [protocolExecution]);

    useEffect(() => {
        if (manualMode)
            return;

        const protocolIndex = protocols.findIndex(p => p.id === selectedProtocol);

        setProtocols([
            ...protocols.slice(0, protocolIndex),
            {
                ...protocols[protocolIndex],
                protocolExecution: {...protocolExecution},
            },
            ...protocols.slice(protocolIndex + 1),
        ]);
    }, [protocolExecution]);

    useEffect(() => {
        if (!state.recordingRoute)
            return;

        setProtocolExecution(current => ({
            ...current,
            recordingRoute: state.recordingRoute,
        }));
    }, [state.recordingRoute])

    const useImperial = state.metricSystem === 'imperial' || (state.metricSystem === undefined && language === 'en');
    const setDesiredTreadmillSpeed = speed => setDesiredTreadmillSpeedKmh(useImperial ? speed * 1.609 : speed);
    const increaseDesiredTreadmillSpeed = delta => setDesiredTreadmillSpeedKmh(speed => useImperial ? (speed / 1.609 + delta) * 1.609 : speed + delta);
    const decreaseDesiredTreadmillSpeed = delta => increaseDesiredTreadmillSpeed(-delta);


    useEffect(() => {
        if (!protocolExecution.running)
            return;

        sendMessageToSessionSocket('SET_TREADMILL_SPEED', desiredTreadmillSpeed);
    }, [desiredTreadmillSpeed]);

    const startManual = () => {
        const countdown = 5;

        sendMessageToSessionSocket('START_PROTOCOL', countdown);

        const countdownTimer = setTimeout(() => {
            sendMessageToSessionSocket('SET_TREADMILL_SPEED', desiredTreadmillSpeed);
            setProtocolExecution(ex => ({
                ...ex,
                recordingTimer: null,
            }));
        }, countdown * 1000);


        const now = new Date();
        now.setSeconds(now.getSeconds() + countdown);

        setProtocolExecution({
            date: now,
            endDate: null,
            running: true,
            recordingTimer: countdownTimer,
        });
    };

    const startProtocol = () => {
        const protocol = protocols.find(p => p.id === selectedProtocol);

        const countdown = 5;

        sendMessageToSessionSocket('START_PROTOCOL', countdown);

        const countdownTimer = setTimeout(() => {
            sendMessageToSessionSocket('SET_TREADMILL_SPEED', protocol.velocidad);

            const startTimer = setTimeout(() => {
                sendMessageToSessionSocket('START_RECORDING', selectedProtocol);
                const stopTimer = setTimeout(() => {
                    sendMessageToSessionSocket('STOP_RECORDING');
                    const remainingTime = protocol.tiempoTotal - protocol.tiempoCalentamiento - protocol.tiempoGrabacion;
                    const finalStopTimer = setTimeout(() => {
                        setProtocolExecution(protocolExecution => ({
                            ...protocolExecution,
                            running: false,
                            recordingTimer: null,
                            endDate: new Date(),
                        }));
                        sendMessageToSessionSocket('STOP_TREADMILL');
                        sendMessageToSessionSocket('STOP_PROTOCOL');
                    }, remainingTime * 1000);

                    setProtocolExecution(protocolExecution => ({
                        ...protocolExecution,
                        recordingTimer: finalStopTimer,
                    }));

                }, protocol.tiempoGrabacion * 1000);

                setProtocolExecution(protocolExecution => ({
                    ...protocolExecution,
                    recordingTimer: stopTimer,
                }));

            }, protocol.tiempoCalentamiento * 1000);

            setProtocolExecution(protocolExecution => ({
                ...protocolExecution,
                recordingTimer: startTimer,
            }));
        }, countdown * 1000);


        const now = new Date();
        now.setSeconds(now.getSeconds() + countdown);

        setProtocolExecution({
            date: now,
            endDate: null,
            running: true,
            recordingTimer: countdownTimer,
        });
    };

    const stopProtocol = () => {
        if (protocolExecution.recordingTimer) {
            clearTimeout(protocolExecution.recordingTimer);
        }

        let newProtocolExecution;
        if (recording) {
            if (selectedProtocol)
                sendMessageToSessionSocket('RESET_RECORDING');

            newProtocolExecution = {...defaultProtocolExecution};
        } else if (recordingRoute) {
            newProtocolExecution = {
                ...protocolExecution,
                endDate: new Date(),
                running: false,
                recordingTimer: null,
            };
        } else {
            newProtocolExecution = {...defaultProtocolExecution};
        }

        setProtocolExecution(newProtocolExecution);

        setDesiredTreadmillSpeed(0);
        sendMessageToSessionSocket('STOP_TREADMILL');
        sendMessageToSessionSocket('STOP_PROTOCOL');
    };

    const toggleManualRecording = selectedIdx => {
        if (recording) {
            sendMessageToSessionSocket('STOP_RECORDING');
        } else {
            setManualProtocols(protocols =>
                protocols.map((protocol, i) => ({
                    ...protocol,
                    active: i === selectedIdx,
                }))
            );
            sendMessageToSessionSocket('START_RECORDING', 0);
        }
    };

    useEffect(() => {
        if (!manualMode)
            return;

        const activeProtocol = manualProtocols.findIndex(protocol => protocol.active);

        if (activeProtocol < 0)
            return;

        setManualProtocols(protocols => ([
            ...protocols.slice(0, activeProtocol),
            {
                ...protocols[activeProtocol],
                recording,
                recordingRoute,
                recordingStartDate,
                recordingEndDate,
            },
            ...protocols.slice(activeProtocol + 1),
        ]));

    }, [recording, recordingStartDate, recordingEndDate, recordingRoute]);

    // const saveRecording = (savingIdx, protocolId) => {
    //     if (manualMode && savingIdx !== undefined) {
    //         sendMessageToSessionSocket('SAVE_RECORDING', JSON.stringify({
    //             route: manualProtocols[savingIdx].protocolExecution.recordingRoute,
    //             protocolId,
    //         }));
    //         setManualProtocols(protocols => ([
    //             ...protocols.slice(0, savingIdx),
    //             {
    //                 ...protocols[savingIdx],
    //                 protocolExecution: {
    //                     ...protocols[savingIdx].protocolExecution,
    //                     savingStatus: 'PROCESSING',
    //                 },
    //             },
    //             ...protocols.slice(savingIdx + 1),
    //         ]));
    //     } else if (!manualMode) {
    //         sendMessageToSessionSocket('SAVE_RECORDING', JSON.stringify({
    //             route: protocolExecution.recordingRoute,
    //             protocolId: null,
    //         }));
    //         setProtocolExecution(current => ({
    //             ...current,
    //             savingStatus: 'PROCESSING',
    //         }));
    //     } else {
    //         console.error('Wrong use of saveRecording');
    //     }
    // };

    useEffect(() => {
        console.log(`SAVING STATUS CHANGED TO "${savingStatus}"`);

        if (savingStatus === 'PROCESSING') {
            console.log('Processing file started');
            const interval = setInterval(() => {
                console.log('querying saving status');
                sendMessageToSessionSocket('GET_SAVING_STATUS');
            }, 500);

            return () => clearInterval(interval);
        }

        if (savingStatus === 'DONE') {
            const protocolCollection = manualMode ? manualProtocols : protocols;
            const updateProtocols = manualMode ? setManualProtocols : setProtocols;

            const savingIdx = protocolCollection.findIndex(protocol => protocol.protocolExecution.savingStatus === 'PROCESSING');
            updateProtocols(protocols => ([
                ...protocols.slice(0, savingIdx),
                {
                    ...protocols[savingIdx],
                    protocolExecution: {
                        ...protocols[savingIdx].protocolExecution,
                        savingStatus: 'DONE',
                    },
                },
                ...protocols.slice(savingIdx + 1),
            ]));
        }
    }, [savingStatus]);

    const resetRecording = selectedIdx => {
        setAngleData({});

        if (manualMode) {
            setState(state => ({
                ...state,
                recordingRoute: manualProtocols[selectedIdx].recordingRoute,
            }));
            setManualProtocols(protocols =>
                protocols.map((protocol, i) => ({
                    ...protocol,
                    active: i === selectedIdx,
                }))
            );
            sendMessageToSessionSocket('RESET_RECORDING', manualProtocols[selectedIdx].recordingRoute);
        } else {
            sendMessageToSessionSocket('RESET_RECORDING', protocolExecution.recordingRoute);
        }
    };

    const toggleAngle = angle => () => {
        sendMessageToDataSocket('toggle', angle);
    };

    const allAnglesEnabled = useMemo(() => {
        return measureKeys
            .filter(k => !blockedAngles.includes(k))
            .every(k => enabledAngles[k]);
    }, [measureKeys, enabledAngles, blockedAngles]);

    const toggleAllAngles = () => {
        let nonBlockedAngles = measureKeys
            .filter(k => !blockedAngles.includes(k));

        if (!allAnglesEnabled)
            nonBlockedAngles = nonBlockedAngles
                .filter(k => !enabledAngles[k]);

        nonBlockedAngles.forEach(k => toggleAngle(k)());
    }

    const handleData = msg => {
        msg = JSON.parse(msg);

        if (msg.type === 'state-change') {
            setState(state => {
                if (msg.data.recordingStartDate)
                    msg.data.recordingStartDate = new Date(msg.data.recordingStartDate);
                if (msg.data.recordingEndDate)
                    msg.data.recordingEndDate = new Date(msg.data.recordingEndDate);
                return {
                    ...state,
                    ...msg.data
                };
            });
        } else if (msg.type === 'angles') {
            if (recording) {
                setAngleData(angleData => updateAngleData(angleData.angles ? [...angleData.angles, msg.data] : [msg.data]));
            } else {
                const newAngles = angleData.angles ? [...angleData.angles, msg.data] : [msg.data];
                setAngleData({...angleData, angles: newAngles});
            }
        } else if (msg.type === 'protocols') {
            console.log('protocols', msg.data)
            setProtocols(msg.data.map(protocol => ({
                ...protocol,
                protocolExecution: {...defaultProtocolExecution},
            })));
        } else if (msg.type === 'actor-props') {
            setCurrentActorProps(msg.data);
        } else if (msg.type === 'error') {
            errorDialog.showMessage(msg.data);
            // snackbar.showMessage(msg.data);
        } else {
            console.log(msg);
        }
    };

    const updateAngleData = (angles) => {
        const {measurementConfig} = state;

        if (!measurementConfig)
            return;

        const stats = {};
        const keyPoints = {};

        for (let key of measureKeys) {
            const {validMin, validMax} = measurementConfig[key];
            let min, max, avg;
            min = max = avg = angles[0][key];
            let numMinKeyPoints = 0,
                numMaxKeyPoints = 0;

            const startTime = angles[0].timestamp;
            const angleKeyPoints = [];
            let keyPointStart = null,
                keyPointIsMax;

            for (let i=1; i<angles.length; ++i) {
                const value = angles[i][key];
                min = Math.min(min, value);
                max = Math.max(max, value);
                avg += value;

                if (keyPointStart === null) {
                    if (value >= validMin && value <= validMax)
                        continue;

                    keyPointStart = angles[i].timestamp;
                    keyPointIsMax = value > validMax;
                } else {
                    if (value < validMin || value > validMax)
                        continue;

                    angleKeyPoints.push(keyPointStart + (angles[i].timestamp - keyPointStart) * 0.5 - startTime);
                    keyPointStart = null;

                    if (keyPointIsMax)
                        ++numMaxKeyPoints;
                    else
                        ++numMinKeyPoints;
                }
            }
            avg /= angles.length;

            stats[key] = {min, max, avg, numMinKeyPoints, numMaxKeyPoints};
            keyPoints[key] = angleKeyPoints;
        }

        return {angles, stats, keyPoints};
    };

    const handleOpenDataSocket = () =>  {
        console.log("Data socket connected");
        setInterval(() => {
            sendMessageToDataSocket('tracking-state');
        }, 1000);
        setInterval(() => sendMessageToDataSocket('all-angles'), 50);
    };

    const handleCloseDataSocket = () => {
        console.log("Data socket connected");
    };

    const handleOpenSessionSocket = () =>  {
        console.log("Session socket connected");
        // sendMessageToSessionSocket('ENABLE_RECORDING', `${sessionName}/${recordingName}`);
        sendMessageToSessionSocket('ENABLE_RECORDING', sessionName);
        setInterval(() =>  sendMessageToSessionSocket('state'), 200);
        sendMessageToSessionSocket('GET_PROTOCOLS');
    };

    const handleCloseSessionSocket = () => {
        console.log("Session socket connected");
    };

    const sendMessageToDataSocket = (type, data) => {
        if (!dataWebsocketRef.current)
            return;

        sendMessage(dataWebsocketRef.current, type, data)
    };

    const sendMessageToSessionSocket = useCallback((type, data) => {
        if (!sessionWebsocketRef.current)
            return;

        sendMessage(sessionWebsocketRef.current, type, data)
    }, [sessionWebsocketRef.current]);

    const sendMessage = (socket, type, data) => {
        const message = { type };
        if (data !== undefined) {
            message.data = data;
        }

        try {
            socket.sendMessage(JSON.stringify(message));
        } catch (err) {

        }
    };

    const websockets = useMemo(() => (
        <>
            <Websocket
                url={`${SERVER_URL}/viewer`}
                onMessage={handleData}
                onOpen={handleOpenDataSocket}
                onClose={handleCloseDataSocket}
                reconnect={true}
                ref={dataWebsocketRef}
            />
            <Websocket
                url={`${SERVER_URL}/sessions`}
                onMessage={handleData}
                onOpen={handleOpenSessionSocket}
                onClose={handleCloseSessionSocket}
                reconnect={true}
                ref={sessionWebsocketRef}
            />
        </>
    ), [state]);

    const {angles: allAngles, stats} = angleData;
    const angles = allAngles && allAngles[allAngles.length - 1];

    let speedPresets = [2, 4, 6, 8];
    let speedUnits = 'km/h';
    let currentSpeed = desiredTreadmillSpeed;

    if (useImperial) {
        currentSpeed /= 1.609;
        speedPresets = speedPresets.map(s => Math.round(s / 1.609));
        speedUnits = 'mph';
    }

    const protocolSettingsDialog = useMemo(() => (
        <ProtocolSettingsDialog
            onSave={protocols => {
                sendMessageToSessionSocket('CONFIGURE_PROTOCOLS', JSON.stringify(protocols));
            }}
            onRestoreDefaults={() => {
                sendMessageToSessionSocket('RESET_PROTOCOLS');
            }}
            currentProtocols={protocols}
            component={
                <IconButton size="small" edge="end" className={classes.settingsButton} color="inherit" aria-label="menu">
                    <SettingsIcon />
                </IconButton>
            }
        />
    ), [sendMessageToSessionSocket, protocols, classes]);

    return (
        <>
            {websockets}
            <div className={classes.root}>
                <Paper
                    className={clsx(classes.paper)}
                >
                    {isTracking ?
                        <>
                            <div
                                className={classes.recordingPanelHeader}
                            >
                                <ChangeActorPropsDialog
                                    component={
                                        <IconButton size="small" edge="end" className={classes.actorPropsButton} color="inherit" aria-label="menu">
                                            <AccessibilityIcon />
                                        </IconButton>
                                    }
                                    onOpen={() => sendMessageToSessionSocket('GET_ACTOR_PROPS')}
                                    onSave={actorProps => sendMessageToSessionSocket('SET_ACTOR_PROPS', JSON.stringify(actorProps))}
                                    currentActorProps={currentActorProps}
                                    useImperial={useImperial}
                                />
                                <ButtonGroup style={{margin: 'auto'}}>
                                    <Button
                                        className={clsx(classes.tabButton, {[classes.tabButtonActive]: manualMode})}
                                        variant='outlined'
                                        onClick={() => setManualMode(true)}
                                        disabled={protocolExecution.running}
                                    >
                                        {t('RecordingView.mode-manual')}
                                    </Button>
                                    <Button
                                        className={clsx(classes.tabButton, {[classes.tabButtonActive]: !manualMode})}
                                        variant='outlined'
                                        onClick={() => setManualMode(false)}
                                        disabled={protocolExecution.running}
                                    >
                                        {t('RecordingView.mode-protocol')}
                                    </Button>
                                </ButtonGroup>
                                {!manualMode &&
                                protocolSettingsDialog}
                            </div>
                            <Divider style={{width: '100%'}} />
                            {manualMode ?
                                <>
                                    <div className={classes.speedButtonContainer}>
                                        <Button
                                            variant='outlined'
                                            className={classes.speedButton}
                                            onClick={() => setDesiredTreadmillSpeed(0)}
                                            disabled={protocolExecution.running && protocolExecution.recordingTimer !== null}
                                            // onClick={() => sendMessageToSessionSocket('SET_TREADMILL_SPEED', 0)}
                                        >
                                            OFF
                                        </Button>
                                        {speedPresets.map((speed, i) => (
                                            <Button
                                                variant='outlined'
                                                key={i}
                                                className={classes.speedButton}
                                                onClick={() => setDesiredTreadmillSpeed(speed)}
                                                disabled={protocolExecution.running && protocolExecution.recordingTimer !== null}
                                            >
                                                {speed} {speedUnits}
                                            </Button>
                                        ))}
                                    </div>
                                    <div className={classes.speedContainer}>
                                        <IconButton
                                            className={clsx(classes.button, protocolExecution.running ? classes.stopButton : classes.playButton)}
                                            onClick={protocolExecution.running ? stopProtocol : startManual}
                                        >
                                            {protocolExecution.running ? <StopIcon/> : <PlayArrowIcon />}
                                        </IconButton>
                                        <IconButton
                                            className={clsx(classes.button, classes.resetButton)}
                                            onClick={() => decreaseDesiredTreadmillSpeed(0.2)}
                                            disabled={desiredTreadmillSpeed < 0.01 || (protocolExecution.running && protocolExecution.recordingTimer !== null)}
                                        >
                                            <RemoveIcon/>
                                        </IconButton>
                                        <div
                                            style={{
                                                fontSize: 30,
                                                fontWeight: 300,
                                            }}
                                        >
                                            {currentSpeed.toFixed(1)} {speedUnits}
                                        </div>
                                        <IconButton
                                            className={clsx(classes.button, classes.saveButton)}
                                            onClick={() => increaseDesiredTreadmillSpeed(0.2)}
                                            disabled={protocolExecution.running && protocolExecution.recordingTimer !== null}
                                        >
                                            <AddIcon/>
                                        </IconButton>
                                    </div>

                                    <Divider style={{width: '100%'}} />

                                    <div className={classes.manualProtocolsContainer}>
                                        {manualProtocols.map((protocol, i) => (
                                            <React.Fragment key={i}>
                                                {i > 0 && <Divider style={{width: '100%'}}/>}
                                                <div className={classes.timerContainer}>
                                                    {(protocol.recording || protocol.recordingStartDate === null) ?
                                                        <IconButton
                                                            className={clsx(classes.button, protocol.recording ? classes.stopButton : classes.recordButton)}
                                                            onClick={() => toggleManualRecording(i)}
                                                            disabled={
                                                                !protocolExecution.running ||
                                                                (recording && !protocol.active) ||
                                                                !streamingPressures ||
                                                                (protocolExecution.running && protocolExecution.recordingTimer !== null)
                                                            }
                                                        >
                                                            {protocol.recording ? <StopIcon/> : <FiberManualRecordIcon/>}
                                                        </IconButton>
                                                        :
                                                        <IconButton
                                                            className={clsx(classes.button, classes.resetButton)}
                                                            onClick={() => resetRecording(i)}
                                                            disabled={protocol.recordingRoute === null || recording}
                                                        >
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    }
                                                    <Timer
                                                        startTime={protocol.recordingStartDate}
                                                        endTime={protocol.recordingEndDate}
                                                    />
                                                    {recording && protocol.active && !streamingPressures &&
                                                    <div className={classes.labelWithIcon}>
                                                        <RecordIcon />
                                                        <span style={{marginLeft: 8}}>{t('RecordingView.capturing-pressures')}</span>
                                                    </div>}
                                                    {/*{protocol.protocolExecution.savingStatus === 'DONE' ?*/}
                                                    {/*    <IconButton*/}
                                                    {/*        className={clsx(classes.button, classes.saveButton)}*/}
                                                    {/*        disabled={protocolExecution.running || savingStatus === 'PROCESSING'}*/}
                                                    {/*        onClick={() => history.push(`/playback/${protocol.protocolExecution.recordingRoute.replace('\\', '/')}`)}*/}
                                                    {/*    >*/}
                                                    {/*        <VisibilityIcon/>*/}
                                                    {/*    </IconButton>*/}
                                                    {/*    :*/}
                                                    {/*    <div*/}
                                                    {/*        style={{position: 'relative'}}*/}
                                                    {/*    >*/}
                                                    {/*        <AssignProtocolDialog*/}
                                                    {/*            component={*/}
                                                    {/*                <IconButton*/}
                                                    {/*                    className={clsx(classes.button, classes.saveButton)}*/}
                                                    {/*                    // disabled={protocol.protocolExecution.recordingRoute === null || recording || savingStatus === 'PROCESSING'}*/}
                                                    {/*                >*/}
                                                    {/*                    <DoneIcon/>*/}
                                                    {/*                </IconButton>*/}
                                                    {/*            }*/}
                                                    {/*            currentProtocols={protocols}*/}
                                                    {/*            onSelect={({protocolId}) => {*/}
                                                    {/*                saveRecording(i, protocolId)*/}
                                                    {/*            }}*/}
                                                    {/*        />*/}
                                                    {/*        {protocol.protocolExecution.savingStatus === 'PROCESSING' &&*/}
                                                    {/*        <CircularProgress*/}
                                                    {/*            size={42}*/}
                                                    {/*            style={{*/}
                                                    {/*                position: 'absolute',*/}
                                                    {/*                top: 0,*/}
                                                    {/*                left: 0,*/}
                                                    {/*            }}*/}
                                                    {/*        />}*/}
                                                    {/*    </div>*/}
                                                    {/*}*/}
                                                </div>
                                            </React.Fragment>
                                        ))}
                                    </div>

                                    {/*<Button*/}
                                    {/*    className={classes.addRecording}*/}
                                    {/*    disableElevation*/}
                                    {/*    variant='contained'*/}
                                    {/*    onClick={() => setManualProtocols(protocols => [...protocols, {...defaultManualProtocol}])}*/}
                                    {/*>*/}
                                    {/*    {t('RecordingView.add-recording')}*/}
                                    {/*</Button>*/}
                                </>
                                :
                                <div style={{width: '100%', overflowY: 'scroll'}}>
                                    {protocols.map(protocol => {
                                        const {
                                            hours,
                                            minutes,
                                            seconds,
                                        } = splitMillisInParts(protocol.tiempoTotal * 1000);
                                        let tiempoTotal = `${fmtNum(minutes, 2)}m${fmtNum(seconds, 2)}s`;
                                        if (hours > 0) {
                                            tiempoTotal = `${fmtNum(hours, 2)}h${tiempoTotal}`;
                                        }
                                        return (
                                            <ExpansionPanel
                                                key={protocol.id}
                                                square
                                                expanded={selectedProtocol === protocol.id}
                                                onChange={handleProtocolChange(protocol.id)}
                                                disabled={protocolExecution.running && selectedProtocol !== protocol.id}
                                            >
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                >
                                                    <Typography style={{flex: 1, textAlign: 'left'}}>{protocol.nombre}</Typography>
                                                    <div className={classes.labelWithIcon} style={{width: 80}}>
                                                        <SpeedIcon/> <span>{protocol.velocidad.toFixed(1)} km/h</span>
                                                    </div>
                                                    <div className={classes.labelWithIcon} style={{width: 85}}>
                                                        <TimerIcon/> <span>{tiempoTotal}</span>
                                                    </div>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <div className={clsx(classes.timerContainer, classes.protocolTimer)}>
                                                        {(protocolExecution.running || protocolExecution.date === null) &&
                                                        <IconButton
                                                            className={clsx(classes.button, protocolExecution.running ? classes.stopButton : classes.playButton)}
                                                            onClick={protocolExecution.running ? stopProtocol : startProtocol}
                                                        >
                                                            {protocolExecution.running ? <StopIcon/> : <PlayArrowIcon />}
                                                        </IconButton>}
                                                        {(!protocolExecution.running && protocolExecution.date !== null) &&
                                                        <IconButton
                                                            className={clsx(classes.button, classes.resetButton)}
                                                            onClick={() => {
                                                                resetRecording();
                                                                setProtocolExecution(protocolExecution => ({
                                                                    ...protocolExecution,
                                                                    date: null,
                                                                    savingStatus: '',
                                                                    recordingRoute: null,
                                                                }));
                                                            }}
                                                            disabled={protocolExecution.recordingRoute === null || recording}
                                                        >
                                                            <DeleteIcon/>
                                                        </IconButton>}
                                                        <Timer
                                                            startTime={protocolExecution.date}
                                                            endTime={protocolExecution.endDate}
                                                        />
                                                        {/*{protocol.protocolExecution.savingStatus === 'DONE' ?*/}
                                                        {/*    <IconButton*/}
                                                        {/*        className={clsx(classes.button, classes.saveButton)}*/}
                                                        {/*        disabled={protocolExecution.recordingRoute === null || protocolExecution.running || savingStatus === 'PROCESSING'}*/}
                                                        {/*        onClick={() => history.push(`/playback/${protocolExecution.recordingRoute.replace('\\', '/')}`)}*/}
                                                        {/*    >*/}
                                                        {/*        <VisibilityIcon/>*/}
                                                        {/*    </IconButton>*/}
                                                        {/*    :*/}
                                                        {/*    <div*/}
                                                        {/*        style={{position: 'relative'}}*/}
                                                        {/*    >*/}
                                                        {/*        <IconButton*/}
                                                        {/*            className={clsx(classes.button, classes.saveButton)}*/}
                                                        {/*            disabled={protocolExecution.recordingRoute === null || protocolExecution.running || savingStatus === 'PROCESSING'}*/}
                                                        {/*            onClick={saveRecording}*/}
                                                        {/*        >*/}
                                                        {/*            <DoneIcon/>*/}
                                                        {/*        </IconButton>*/}
                                                        {/*        {protocol.protocolExecution.savingStatus === 'PROCESSING' &&*/}
                                                        {/*        <CircularProgress*/}
                                                        {/*            size={42}*/}
                                                        {/*            style={{*/}
                                                        {/*                position: 'absolute',*/}
                                                        {/*                top: 0,*/}
                                                        {/*                left: 0,*/}
                                                        {/*            }}*/}
                                                        {/*        />}*/}
                                                        {/*    </div>*/}
                                                        {/*}*/}
                                                    </div>
                                                    {recording &&
                                                    <div className={classes.labelWithIcon}>
                                                        <RecordIcon />
                                                        <span style={{marginLeft: 8}}>{t('RecordingView.capturing-data')}</span>
                                                    </div>}
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        )
                                    })}
                                </div>
                            }
                            <Divider style={{width: '100%'}} />
                            <div className={classes.bottomActions}>

                                {manualMode &&
                                <Button
                                    className={classes.addRecording}
                                    disableElevation
                                    variant='contained'
                                    onClick={() => setManualProtocols(protocols => [...protocols, {...defaultManualProtocol}])}
                                >
                                    {t('RecordingView.add-recording')}
                                </Button>}
                                <Button
                                    className={classes.emergencyStop}
                                    disableElevation
                                    variant='contained'
                                    onClick={() => sendMessageToSessionSocket('EMERGENCY_STOP')}
                                >
                                    {t('RecordingView.emergency-stop')}
                                </Button>
                            </div>
                        </>
                        :
                        <Typography variant="h5" className={classes.title}>
                            {t('RecordingView.patient-not-detected')}
                        </Typography>
                    }
                </Paper>
                {isTracking && angles &&
                <Paper className={classes.paper}>
                    <div className={classes.measurementsHeader}>
                        <Typography variant="h5">{t('RecordingView.measures-table.title')}</Typography>
                        <MeasurementSettingsDialog
                            onSave={settings => {
                                sendMessageToSessionSocket('CONFIGURE_LIMITS', JSON.stringify(settings));
                            }}
                            currentSettings={state.measurementConfig || {}}
                            component={
                                <IconButton size="small" edge="end" className={classes.settingsButton} color="inherit" aria-label="menu">
                                    <SettingsIcon />
                                </IconButton>
                            }
                        />
                    </div>
                    <TableContainer className={classes.tableContainer}>
                        <Table className={classes.table} aria-label="simple table" size="small">
                            <TableHead>
                                <TableRow>
                                    {/*<TableCell>*/}
                                    {/*    <IconButton*/}
                                    {/*        onClick={() => toggleAllAngles()}*/}
                                    {/*        size='small'*/}
                                    {/*    >*/}
                                    {/*        { allAnglesEnabled ? <VisibilityIcon fontSize='small' /> : <VisibilityOffIcon fontSize='small' />}*/}
                                    {/*    </IconButton>*/}
                                    {/*</TableCell>*/}
                                    <TableCell>{t('RecordingView.measures-table.description')}</TableCell>
                                    <TableCell align='right'>{t('RecordingView.measures-table.value')}</TableCell>
                                    <TableCell align='right'>{t('RecordingView.measures-table.min')}</TableCell>
                                    <TableCell align='right'>{t('RecordingView.measures-table.max')}</TableCell>
                                    <TableCell align='right'>{t('RecordingView.measures-table.avg')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {measureKeys.map(key => (
                                    <TableRow key={key}>
                                        {/*<TableCell>*/}
                                        {/*    {!blockedAngles.includes(key) &&*/}
                                        {/*    <IconButton*/}
                                        {/*        onClick={toggleAngle(key)}*/}
                                        {/*        size='small'*/}
                                        {/*    >*/}
                                        {/*        { enabledAngles[key] ? <VisibilityIcon fontSize='small' /> : <VisibilityOffIcon fontSize='small' />}*/}
                                        {/*    </IconButton>}*/}
                                        {/*</TableCell>*/}
                                        <TableCell>
                                            {measureLabelsLocalized[language][key]}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {angles[key] !== null && !isNaN(angles[key]) && angles[key].toFixed(0)}
                                        </TableCell>
                                        {stats ?
                                            <>
                                                <TableCell align='right'>
                                                    {/*<WarningIcon className={clsx(classes.angleAlert, classes.angleWarning)} />*/}
                                                    {stats[key].min !== null && stats[key].min.toFixed(0)}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {/*<WarningIcon className={clsx(classes.angleAlert, classes.angleError)} />*/}
                                                    {stats[key].max !== null && stats[key].max.toFixed(0)}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {stats[key].avg !== null && stats[key].avg.toFixed(0)}
                                                </TableCell>
                                            </>
                                            :
                                            <TableCell colSpan={3}>{t('RecordingView.measures-table.no-data')}</TableCell>
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>}
            </div>
        </>
    );
}