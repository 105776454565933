import {makeStyles} from "@material-ui/core/styles";

export const useGlobalStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    backButton: {
        marginRight: theme.spacing(2),
    },
    settingsButton: {
        marginLeft: theme.spacing(2),
    },
    title: {
        flex: 1,
    },
    connectionStatus: {
        marginRight: theme.spacing(4),
        marginLeft: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        fontSize: 12,
        '& svg': {
            fontSize: 'inherit',
            marginRight: theme.spacing(0.5),
        },
        '&.connected': {
            color: '#2CBD96',
        },
        '&.disconnected': {
            color: '#E94F4F',
        },
    },
}));