import {measureAbreviatures} from "../../constants";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {useCallbackRef} from "use-callback-ref";
import {fmtNum, splitMillisInParts} from "../recording/Timer";


const useStyles = makeStyles(theme => ({
    timeSlider: {
        height: 50,
        width: '100%',
        backgroundColor: '#eee',
        position: 'relative',
        overflow: 'hidden',
        // marginTop: theme.spacing(2),
        '& div': {
            height: '100%',
            backgroundColor: theme.palette.primary.main,
            position: 'absolute',
        },
    },
    timeTick: {
        height: 10,
        backgroundColor: '#666',
        width: 1,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        pointerEvents: 'none',
        bottom: 0,
        '& span': {
            transform: 'translateY(-20px)',
        },
    },
    keyPointMarker: {
        height: '100%',
        backgroundColor: 'black',
        width: 2,
        position: 'absolute',
        display: 'block',
        pointerEvents: 'none',
    },
    keyPointMarkerIcon: {
        position: 'absolute',
        backgroundColor: '#dddddd',
        borderRadius: 4,
        padding: 4,
        color: 'black',
        fontSize: 11,
        transform: 'translate(-17px, 4px)',
        width: 36,
        textAlign: 'center',
        boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.75)',
        '& span': {
            position: 'absolute',
            backgroundColor: '#666666',
            width: 1,
            height: 42,
            top: 24,
            left: 18,
        },
    },
}));

export default function TimeLine({enabledKeyPoints, keyPoints, duration, onKeyPointClick, onClick, timePlayed}) {
    const classes = useStyles();
    const [timeSpacing, setTimeSpacing] = useState(null);
    const [offsetScale, setOffsetScale] = useState([0.0, 1.0]);
    const [dragStartX, setDragStartX] = useState(null);

    function updateTimeSpacing(el) {
        if (!el)
            return;

        const width = el.offsetWidth;
        const minWidth = 10;
        const timeSpacings = [0.1, 0.2, 0.5, 1, 2, 5, 10];
        let s = 0;

        for (let i=0; i<timeSpacings.length; ++i) {
            s = timeSpacings[i];
            const numSpacings = duration / s;
            const spacing = width / numSpacings;
            if (spacing >= minWidth)
                break;
        }

        if (s !== timeSpacing)
            setTimeSpacing(s);
    }

    const timelineRef = useCallbackRef(null, div => {
        updateTimeSpacing(div);
    });

    useEffect(() => {
        function onResize(ev) {
            updateTimeSpacing(timelineRef.current);
        }

        window.addEventListener('resize', onResize);

        return () => window.removeEventListener('resize', onResize);
    }, []);

    const tickMarks = [];

    const [offset, scale] = offsetScale;

    const timeOffset = timelineRef.current ? duration * offset / timelineRef.current.offsetWidth : 0;

    if (timeSpacing) {
        const numTicks = Math.floor(duration / timeSpacing) + 1;
        for (let i=0; i<numTicks; ++i) {
            const time = i * timeSpacing;
            const mark = [100 * time / duration, null];

            if (i % 10 === 0)
                mark[1] = splitMillisInParts((timeOffset + time / scale) * 1000);

            tickMarks.push(mark);
        }
    }

    const percentagePlayed = 100 * (timePlayed - timeOffset) / duration * scale;

    return (
        <>
            <div
                style={{
                    position: 'relative',
                    width: '100%',
                    height: 70,
                    overflowX: 'hidden',
                }}
            >
                {enabledKeyPoints.map(key => {
                    return keyPoints[key].map((kp, i) => {
                        const position = 100 * (((kp - timeOffset) / duration) * scale);
                        return (
                            <div
                                className={classes.keyPointMarkerIcon}
                                key={`kp-${key}-${i}`}
                                style={{
                                    left: `${position}%`,
                                }}
                                onClick={() => onKeyPointClick(kp)}
                            >
                                {measureAbreviatures[key]}
                                <span>
                                </span>
                            </div>
                        );
                    })
                })}
                {tickMarks && tickMarks.map(([percentage, content], i) => (
                    <div
                        key={i}
                        className={classes.timeTick}
                        style={{
                            left: `${percentage}%`,
                            height: content ? 15 : 7,
                        }}
                    >
                        {content && <span>{fmtNum(content.hours, 2)}:{fmtNum(content.minutes, 2)}:{fmtNum(content.seconds, 2)}.{fmtNum(content.millis, 3)}</span>}
                    </div>
                ))}
            </div>
            <div
                ref={timelineRef}
                className={classes.timeSlider}
                onTouchStart={ev => {
                    ev.preventDefault();

                    if (ev.touches.length === 1) {
                        const rect = ev.target.getBoundingClientRect();
                        const x = ev.changedTouches[0].clientX - rect.left;
                        setDragStartX({start: x, current: x});
                    } else if (ev.touches.length === 2) {
                        const distance = Math.abs(ev.touches[0].clientX - ev.touches[1].clientX);
                        setDragStartX({distance});
                    } else {
                        setDragStartX(null);
                    }
                }}
                onTouchMove={ev => {
                    ev.preventDefault();

                    if (dragStartX === null)
                        return;

                    const rect = ev.target.getBoundingClientRect();
                    if (dragStartX.start !== undefined) {
                        const x = ev.touches[0].clientX - rect.left;
                        const [offset, scale] = offsetScale;

                        const diff = (x - dragStartX.current) / scale;

                        const newOffset = Math.min(Math.max(offset - diff, 0),  ev.target.offsetWidth * scale - ev.target.offsetWidth);

                        setDragStartX({...dragStartX, current: x});
                        setOffsetScale([newOffset, scale]);
                    } else {
                        const distance = Math.abs(ev.touches[0].clientX - ev.touches[1].clientX);
                        const distanceDiff = distance - dragStartX.distance;

                        const x = Math.min(ev.touches[0].clientX, ev.touches[1].clientX) + distance * 0.5 - rect.left;

                        const scaleDelta = 1 + 0.010 * Math.abs(distanceDiff);
                        const newScale = Math.max(distanceDiff > 0 ? scale * scaleDelta : scale / scaleDelta, 1);
                        const newOffset = Math.min(Math.max(offset - x / newScale + x / scale, 0),  ev.target.offsetWidth * newScale - ev.target.offsetWidth);

                        setOffsetScale([newOffset, newScale]);
                        setDragStartX({distance});
                    }
                }}
                onTouchEnd={ev => {
                    ev.preventDefault();

                    if (dragStartX === null)
                        return;

                    if (dragStartX.start === undefined)
                        setDragStartX(null);

                    const rect = ev.target.getBoundingClientRect();
                    if (ev.touches.length > 0 || ev.changedTouches.length !== 1) {
                        return;
                    }

                    const x = ev.changedTouches[0].clientX - rect.left;
                    const diff = x - dragStartX.start;

                    if (Math.abs(diff) < 5) {
                        const [offset, scale] = offsetScale;
                        const percentage = (offset + x / scale) / ev.target.offsetWidth;
                        onClick(percentage * duration);
                    }

                    setDragStartX(null);
                }}
                onMouseDown={ev => {
                    const rect = ev.target.getBoundingClientRect();
                    const x = ev.clientX - rect.left;
                    setDragStartX({start: x, current: x});
                }}
                onMouseUp={ev => {
                    const rect = ev.target.getBoundingClientRect();
                    const x = ev.clientX - rect.left;
                    const diff = x - dragStartX.start;

                    if (Math.abs(diff) < 5) {
                        const [offset, scale] = offsetScale;
                        const percentage = (offset + x / scale) / ev.target.offsetWidth;
                        onClick(percentage * duration);
                    }

                    setDragStartX(null);
                }}
                onMouseMove={ev => {
                    if (dragStartX === null)
                        return;

                    const rect = ev.target.getBoundingClientRect();
                    const x = ev.clientX - rect.left;
                    const [offset, scale] = offsetScale;

                    const diff = (x - dragStartX.current) / scale;

                    const newOffset = Math.min(Math.max(offset - diff, 0),  ev.target.offsetWidth * scale - ev.target.offsetWidth);

                    setDragStartX({...dragStartX, current: x});
                    setOffsetScale([newOffset, scale]);

                    console.log('drag', diff, offset, scale, newOffset);
                }}
                onWheel={ev => {
                    const rect = ev.target.getBoundingClientRect();
                    const x = ev.clientX - rect.left;

                    const scaleDelta = 1.25;
                    const newScale = Math.max(ev.deltaY < 0 ? scale * scaleDelta : scale / scaleDelta, 1);
                    const newOffset = Math.min(Math.max(offset - x / newScale + x / scale, 0),  ev.target.offsetWidth * newScale - ev.target.offsetWidth);

                    setOffsetScale([newOffset, newScale]);
                }}
            >
                <div style={{width: `${percentagePlayed}%`, pointerEvents: 'none'}}/>
            </div>
        </>
    );
}