
export const measureLabelsLocalized = {
    'en': {
        shoulderDifference: 'Shoulder difference',
        shoulderDz: 'Shoulder dZ',
        shoulderDy: 'Shoulder level',
        shoulderAngleXY: 'Shoulder angle XY',
        shoulderAngleXZ: 'Trunk rotation',
        hipDifference: 'Hip difference',
        hipDz: 'Hip dZ',
        hipDy: 'Hip level',
        hipAngleXY: 'Hip angle XY',
        hipAngleXZ: 'Hip rotation',
        footDistance: 'Foot distance',
        spineAngleYX: 'Spine angle YX',
        spineAngleYZ: 'Spine angle YZ',
        spineDx: 'Spine dX',
        spineDz: 'Spine dZ',
        leftUpperAngle: 'Hip flexion',
        leftLowerAngle: 'Knee flexion',
        leftKneeValgusAngle: 'Varus-valgus',
        leftFootFlexion: ['Dorsal flexion' ,'Plantar flexion'],
        leftFootAbduction: 'Foot abduction',
        rightUpperAngle: 'Hip flexion',
        rightLowerAngle: 'Knee flexion',
        rightKneeValgusAngle: 'Varus-valgus',
        rightFootFlexion: ['Dorsal flexion' ,'Plantar flexion'],
        rightFootAbduction: 'Foot abduction',
    },
    'es-ES': {
        shoulderDifference: 'Nivel hombros',
        shoulderDz: 'Shoulder dZ',
        shoulderDy: 'Shoulder level',
        shoulderAngleXY: 'Shoulder angle XY',
        shoulderAngleXZ: 'Rotación tronco',
        hipDifference: 'Nivel caderas',
        hipDz: 'Hip dZ',
        hipDy: 'Hip level',
        hipAngleXY: 'Hip angle XY',
        hipAngleXZ: 'Rotación caderas',
        footDistance: 'Foot distance',
        spineAngleYX: 'Spine angle YX',
        spineAngleYZ: 'Spine angle YZ',
        spineDx: 'Spine dX',
        spineDz: 'Spine dZ',
        leftUpperAngle: 'Flexión caderas (I)',
        leftLowerAngle: 'Flexión rodilla (I)',
        leftKneeValgusAngle: 'Rodilla varo-valgo (I)',
        leftFootFlexion: ['Flexión dorsal', 'plantar (I)'],
        leftFootAbduction: 'Abduccion pie (I)',
        rightUpperAngle: 'Flexión caderas (D)',
        rightLowerAngle: 'Flexión rodilla (D)',
        rightKneeValgusAngle: 'Rodilla varo-valgo (D)',
        rightFootFlexion: ['Flexión dorsal', 'plantar (D)'],
        rightFootAbduction: 'Abduccion pie (D)',
    },
};

export const measureAbreviatures = {
    shoulderDifference: 'ShoDif',
    shoulderDz: 'Shoulder dZ',
    shoulderDy: 'SDy',
    shoulderAngleXY: 'Shoulder angle XY',
    shoulderAngleXZ: 'Shoulder angle XZ',
    hipDifference: 'HipDif',
    hipDz: 'Hip dZ',
    hipDy: 'Hip dY',
    hipAngleXY: 'Hip angle XY',
    hipAngleXZ: 'Hip angle XZ',
    footDistance: 'Foot distance',
    spineAngleYX: 'Spine angle YX',
    spineAngleYZ: 'Spine angle YZ',
    spineDx: 'Spine dX',
    spineDz: 'Spine dZ',
    leftUpperAngle: 'UL',
    leftLowerAngle: 'Lower (L)',
    leftKneeValgusAngle: 'Knee Valgus (L)',
    leftFootFlexion: 'Foot flexion (L)',
    leftFootAbduction: 'Foot abduction (L)',
    rightUpperAngle: 'Upper (R)',
    rightLowerAngle: 'Lower (R)',
    rightKneeValgusAngle: 'Knee Valgus (R)',
    rightFootFlexion: 'Foot flexion (R)',
    rightFootAbduction: 'Foot abduction (R)',
};

export const blockedAngles = [
    'shoulderDy',
    'shoulderDz',
    'shoulderAngleXY',
    'hipDy',
    'hipDz',
    'hipAngleXY',
    'footDistance',
    'spineAngleYX',
    'spineAngleYZ',
    'spineDx',
    'spineDz',
];

export const measureKeys = Object.keys(measureLabelsLocalized['en']).filter(key => !blockedAngles.includes(key));