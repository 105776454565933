import React, {useCallback, useMemo, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import VideocamIcon from '@material-ui/icons/Videocam';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Moment from "react-moment";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import useFieldSet from "./useFieldSet";

const useStyles = makeStyles(theme => ({
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
    table: {
        margin: 'auto',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        maxWidth: 500,
        '& .MuiTableCell-sizeSmall': {
            padding: 6,
        },
        '& .MuiTableCell-sizeSmall:last-of-type, & .MuiTableCell-sizeSmall:first-of-type': {
            width: '1%',
            whiteSpace: 'nowrap',
        },
    },
    miniIcon: {
        color: theme.palette.secondary.main,
        height: 18,
        marginBottom: -4,
    },
}));


export default function ValidateSessionDialog({component, onSave, selectedRecordings, canPedirPlantillas}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [pedirPlantillas, setPedirPlantillas] = useState(false);
    const [material, setMaterial] = useState('');
    const { values, setFieldValue, renderField } = useFieldSet({
        address: '',
        phone: '',
        email: '',
    });
    const { t , i18n: { styleName } } = useTranslation();

    const handleClickOpen = () => {
        setOpen(true);
        if (styleName === 'footadapt') {
            setMaterial('Sin material')
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {React.cloneElement(component, {
                onClick: handleClickOpen,
            })}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullScreen>
                <DialogTitle id="form-dialog-title">{t('ValidateSessionDialog.title')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>{t('ValidateSessionDialog.validar-grabaciones')}:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Table className={classes.table} size="small" aria-label="sessions">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('SessionBrowser.recordings-table.time')}</TableCell>
                                        <TableCell>{t('SessionBrowser.recordings-table.protocol')}</TableCell>
                                        <TableCell>{t('SessionBrowser.recordings-table.duration')}</TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedRecordings.map((row, i) => {
                                        let protocolName = '';

                                        if (row.protocolo)
                                            protocolName = row.protocolo.nombre
                                        else if (row.info && row.info.customName)
                                            protocolName = row.info.customName;
                                        else
                                            protocolName = 'Manual';

                                        if (row.info && (row.info.protocolId > 0 || row.info.customName) && row.info.isManual)
                                            protocolName = `${protocolName} (M)`;

                                        return (
                                            <TableRow key={row.fecha}>
                                                <TableCell><Moment format='HH:mm'>{row.fecha}</Moment></TableCell>
                                                <TableCell>
                                                    {protocolName}
                                                </TableCell>
                                                <TableCell>
                                                    {row.duracion !== null && <><VideocamIcon className={classes.miniIcon} /> {row.duracion}</>}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Grid>
                        {canPedirPlantillas ?
                            <>
                                <Grid item xs={12}>
                                    <Typography>{t('ValidateSessionDialog.consulta-validada-archivar')}.</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <div style={{display: 'flex', flexDirection: 'column', gap: '24px'}}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={pedirPlantillas}
                                                    onChange={ev => setPedirPlantillas(ev.target.checked)}
                                                    name="pedir-plantillas"
                                                    color="primary"
                                                />
                                            }
                                            label={t('ValidateSessionDialog.pedir-plantillas')}
                                        />
                                        {pedirPlantillas && styleName !== 'footadapt' &&
                                        <FormControl component="fieldset" error={pedirPlantillas && material === ''}>
                                            <FormLabel component="legend">{t('ValidateSessionDialog.material.label')}</FormLabel>
                                            <RadioGroup
                                                aria-label="material"
                                                name="material"
                                                value={material}
                                                onChange={ev => setMaterial(ev.target.value)}
                                            >
                                                <FormControlLabel value="azul" control={<Radio />} label={t('ValidateSessionDialog.material.azul')} />
                                                <FormControlLabel value="sport" control={<Radio />} label={t('ValidateSessionDialog.material.sport')} />
                                            </RadioGroup>
                                            {pedirPlantillas && material === '' && <FormHelperText>{t('ValidateSessionDialog.material.error')}</FormHelperText>}
                                        </FormControl>}
                                    </div>
                                </Grid>
                                {pedirPlantillas &&
                                <>
                                    <Grid item xs={6}>
                                        {renderField('address', t('ValidateSessionDialog.input-address'), {error: values.address === ''})}
                                        {renderField('phone', t('ValidateSessionDialog.input-phone'), {error: values.phone === ''})}
                                        {renderField('email', t('ValidateSessionDialog.input-email'), {error: values.email === ''})}
                                        {/*<FormControl component="fieldset" error={pedirPlantillas && material === ''}>*/}
                                        {/*    <FormLabel component="legend">{t('ValidateSessionDialog.material.label')}</FormLabel>*/}
                                        {/*    <RadioGroup*/}
                                        {/*        aria-label="material"*/}
                                        {/*        name="material"*/}
                                        {/*        value={material}*/}
                                        {/*        onChange={ev => setMaterial(ev.target.value)}*/}
                                        {/*    >*/}
                                        {/*        <FormControlLabel value="azul" control={<Radio />} label={t('ValidateSessionDialog.material.azul')} />*/}
                                        {/*        <FormControlLabel value="sport" control={<Radio />} label={t('ValidateSessionDialog.material.sport')} />*/}
                                        {/*    </RadioGroup>*/}
                                        {/*    {pedirPlantillas && material === '' && <FormHelperText>{t('ValidateSessionDialog.material.error')}</FormHelperText>}*/}
                                        {/*</FormControl>*/}
                                    </Grid>
                                </>}
                            </>
                            :
                            <Grid item xs={12}>
                                <Typography>{t('ValidateSessionDialog.consulta-validada-apli')}.</Typography>
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.buttons}>
                    <Button onClick={handleClose} color="primary">
                        {t('ValidateSessionDialog.cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            onSave({
                                selectedRecordings,
                                pedirPlantillas,
                                material,
                                ...values,
                            });
                            handleClose();
                        }}
                        color="primary"
                        variant="contained"
                        disabled={pedirPlantillas && (material === '' || Object.values(values).some(v => v === ''))}
                    >
                        {t('ValidateSessionDialog.ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}