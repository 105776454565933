import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 0C5.38293 0 0 5.38293 0 12C0 18.6171 5.38293 24 12 24C18.6171 24 24 18.6171 24 12C24 5.38293 18.6171 0 12 0Z" fill="#F44336"/>
            <path d="M15.9999 11.9999C15.9999 14.2091 14.2091 15.9999 11.9999 15.9999C9.79077 15.9999 8 14.2091 8 11.9999C8 9.79077 9.79077 8 11.9999 8C14.2091 8 15.9999 9.79077 15.9999 11.9999Z" fill="#FAFAFA"/>
        </svg>
    </SvgIcon>
)