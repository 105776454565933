import React, {useRef} from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SettingsIcon from '@material-ui/icons/Settings';
import ListSubheader from "@material-ui/core/ListSubheader";
import {useTranslation} from "react-i18next";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Switch from "@material-ui/core/Switch";
import Websocket from "react-websocket";
import {SERVER_URL} from "./App";

export default function MainSettings() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [state, setState] = React.useState({
        treadmillBarLeftAtFront: false,
        treadmillBarRightAtFront: false,
        treadmillGroundLightWhenRecording: false,
        treadmillGroundLight: true,

    });
    const { t, i18n } = useTranslation();
    const websocketRef = useRef();

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleData = msg => {
        msg = JSON.parse(msg);

        if (msg.type === 'state-change') {

            setState(msg.data);
            // console.log('settings updated state', msg.data)
        }
    };

    const handleOpen = () =>  {
        console.log("Connected to sessions WS");
        sendMessage('state');
    };

    const handleClose = () => {
        console.log("Disconnected from sessions WS");
    };

    const sendMessage = (type, data) => {
        if (!websocketRef.current) {
            console.warn('Websocket not ready');
            return;
        }

        const message = { type };
        if (data !== undefined) {
            message.data = data;
        }
        websocketRef.current.sendMessage(JSON.stringify(message));
    };

    const moveBarToFront = bar => checked => {
        sendMessage(`MOVE_${bar}_BAR_${checked ? 'FRONT' : 'BACK'}`);
    };
    const moveLeftToFront = moveBarToFront('LEFT');
    const moveRightToFront = moveBarToFront('RIGHT');

    function toggleGroundLightOnRecord() {
        sendMessage('TOGGLE_GROUND_LIGHT_ON_RECORD');
    }

    function toggleGroundLight() {
        sendMessage('TOGGLE_GROUND_LIGHT');
    }

    function changeLanguage(lng) {
        i18n.changeLanguage(lng);
        sendMessage('CHANGE_LANGUAGE', lng);
    }

    function changeMetricSystem(lng) {
        sendMessage('CHANGE_METRIC_SYSTEM', lng);
    }

    return (
        <>
            <Websocket
                url={`${SERVER_URL}/sessions`}
                onMessage={handleData}
                onOpen={handleOpen}
                onClose={handleClose}
                reconnect={true} debug={true}
                reconnectIntervalInMilliSeconds={10000}
                ref={websocketRef}
            />
            <IconButton
                aria-controls="settings-menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
            >
                <SettingsIcon />
            </IconButton>
            <Menu
                id="settings-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                PaperProps={{
                    style: {
                        width: 350,
                    },
                }}
            >
                <ListSubheader>{t('MainSettings.languages')}</ListSubheader>
                <MenuItem onClick={() => changeLanguage('es-ES')}>Español</MenuItem>
                <MenuItem divider onClick={() => changeLanguage('en')}>English</MenuItem>
                <ListSubheader>{t('MainSettings.metric-system')}</ListSubheader>
                <MenuItem onClick={() => changeMetricSystem('international')}>{t('MainSettings.international-units')}</MenuItem>
                <MenuItem divider onClick={() => changeMetricSystem('imperial')}>{t('MainSettings.imperial-units')}</MenuItem>
                <ListSubheader>{t('MainSettings.bars')}</ListSubheader>
                <ListItem>
                    <ListItemText id="move-left-bar-label" primary={t('MainSettings.left-bar')} />
                    <ListItemSecondaryAction>
                        <Switch
                            edge="end"
                            onChange={ev => moveLeftToFront(ev.target.checked)}
                            checked={state.treadmillBarLeftAtFront}
                            inputProps={{ 'aria-labelledby': 'move-left-bar-label' }}
                            color='primary'
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                    <ListItemText id="move-right-bar-label" primary={t('MainSettings.right-bar')} />
                    <ListItemSecondaryAction>
                        <Switch
                            edge="end"
                            onChange={ev => moveRightToFront(ev.target.checked)}
                            checked={state.treadmillBarRightAtFront}
                            inputProps={{ 'aria-labelledby': 'move-right-bar-label' }}
                            color='primary'
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListSubheader>{t('MainSettings.lighting')}</ListSubheader>
                <ListItem>
                    <ListItemText id="toggle-ground-light-on-record-label" primary={t('MainSettings.ground-light')} />
                    <ListItemSecondaryAction>
                        <Switch
                            edge="end"
                            onChange={() => toggleGroundLightOnRecord()}
                            checked={state.treadmillGroundLightWhenRecording}
                            inputProps={{ 'aria-labelledby': 'toggle-ground-light-on-record-label' }}
                            color='primary'
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem divider>
                    <ListItemText id="toggle-ground-light-label" primary={t('MainSettings.toggle-ground-light')} />
                    <ListItemSecondaryAction>
                        <Switch
                            edge="end"
                            onChange={() => toggleGroundLight()}
                            checked={state.treadmillGroundLight}
                            inputProps={{ 'aria-labelledby': 'toggle-ground-light-label' }}
                            color='primary'
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                <ListSubheader>{t('MainSettings.other')}</ListSubheader>
                {/*<MenuItem onClick={() => {*/}
                {/*    sendMessage('RESTART_PRESSURE_SENSOR');*/}
                {/*    handleMenuClose();*/}
                {/*}}>{t('MainSettings.reset-pressure-sensor')}</MenuItem>*/}
                <MenuItem onClick={() => {
                    sendMessage('RESET_TRACKING');
                    handleMenuClose();
                }}>{t('MainSettings.reset-tracker')}</MenuItem>
                <MenuItem onClick={() => {
                    sendMessage('RESET_SERVICE');
                    handleMenuClose();
                }}>{t('MainSettings.reset-service')}</MenuItem>
                <MenuItem onClick={() => {
                    sendMessage('RESTART_COMPUTER');
                    handleMenuClose();
                }}>{t('MainSettings.restart-computer')}</MenuItem>
                <MenuItem onClick={() => {
                    sendMessage('SHUTDOWN_COMPUTER');
                    handleMenuClose();
                }}>{t('MainSettings.shutdown-computer')}</MenuItem>
            </Menu>
        </>
    );
}