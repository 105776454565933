import React, {useCallback, useMemo, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import useFieldSet from "./useFieldSet";

const useStyles = makeStyles(theme => ({
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
}));


const defaultReportData = process.env.NODE_ENV === 'development' ?
    {
        name: 'Paciente de prueba',
        age: '35',
        feetNumber: '44',
        height: '186',
        weight: '85',
        activityLevel: 'Deportista',
        sport: 'Ciclismo',
        address: '',
        email: '',
        phone: '',
        referrer: '',
        observations:
            'Lorem ipsum sit dolor met consecteur. Lorem ipsum sit dolor met consecteur. \n ' +
            'Lorem ipsum sit dolor met consecteur. Lorem ipsum sit dolor met consecteur. ' +
            'Lorem ipsum sit dolor met consecteur. Lorem ipsum sit dolor met consecteur. ',
        addScreenshots: true,
    } : {
        name: '',
        age: '',
        feetNumber: '',
        height: '',
        weight: '',
        activityLevel: '',
        sport: '',
        address: '',
        email: '',
        phone: '',
        referrer: '',
        observations: '',
        addScreenshots: true,
    };

export default function EditPatientInfoDialog({component, onSave, defaultValues}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const { values, setFieldValue, renderField } = useFieldSet(defaultValues || defaultReportData);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {React.cloneElement(component, {
                onClick: handleClickOpen,
            })}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullScreen>
                <DialogTitle id="form-dialog-title">{t('EditPatientInfoDialog.title')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {renderField('name', t('EditPatientInfoDialog.input-name'))}
                        </Grid>
                        <Grid item xs={4}>
                            {renderField('feetNumber', t('EditPatientInfoDialog.input-feetNumber'))}
                        </Grid>
                        {/*<Grid item xs={4}>*/}
                        {/*    {renderField('height', t('EditPatientInfoDialog.input-height'))}*/}
                        {/*</Grid>*/}
                        <Grid item xs={4}>
                            {renderField('weight', t('EditPatientInfoDialog.input-weight'))}
                        </Grid>
                        <Grid item xs={4}>
                            {renderField('age', t('EditPatientInfoDialog.input-age'))}
                        </Grid>
                        <Grid item xs={4}>
                            {renderField('activityLevel', t('EditPatientInfoDialog.input-activityLevel'))}
                        </Grid>
                        <Grid item xs={4}>
                            {renderField('sport', t('EditPatientInfoDialog.input-sport'))}
                        </Grid>
                        <Grid item xs={4}>
                            {renderField('referrer', t('EditPatientInfoDialog.input-referrer'))}
                        </Grid>
                        <Grid item xs={12}>
                            {renderField(
                                'observations',
                                t('EditPatientInfoDialog.input-observations'),
                                {
                                    multiline: true,
                                    rows: 5,
                                }
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox checked={values.addScreenshots} onChange={setFieldValue} name='addScreenshots' />}
                                label={t('EditPatientInfoDialog.input-addScreenshots')}
                                color='primary'
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.buttons}>
                    <Button onClick={handleClose} color="primary">
                        {t('EditPatientInfoDialog.cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            onSave(values);
                            handleClose();
                        }}
                        color="primary"
                        variant="contained"
                    >
                        {t('EditPatientInfoDialog.ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}