import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
}));

export default function DeleteDialog({component, objectType, onDelete, okLabel = null, title}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {React.cloneElement(component, {
                onClick: handleClickOpen,
            })}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{title || `${t('DeleteDialog.default-title')} ${objectType || ''}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('DeleteDialog.text')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.buttons}>
                    <Button onClick={handleClose} color="primary">
                        {t('DeleteDialog.cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            onDelete();
                            handleClose();
                        }}
                        color="primary"
                        variant="contained"
                    >
                        {okLabel || t('DeleteDialog.ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}