import React, {Suspense, useCallback, useEffect, useRef, useState} from 'react';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {BrowserRouter as Router, Route, Switch, useHistory} from 'react-router-dom';
import {useGlobalStyles} from "./GlobalStyles";
import SessionBrowser from "./components/sessions/SessionBrowser";
import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider, withStyles} from "@material-ui/core/styles";
import RecordingView from "./components/recording/RecordingView";
import PlaybackView from "./components/playback/PlaybackView";
import useNoSleep from "use-no-sleep";
import {useTranslation} from "react-i18next";
import MainSettings from "./MainSettings";
import {SnackbarProvider} from "material-ui-snackbar-provider";
import Websocket from "./components/Websocket";
import clsx from "clsx";
import WifiIcon from '@material-ui/icons/Wifi';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import ErrorDialogProvider from "./components/ErrorDialogProvider/ErrorDialogProvider";


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#21b7e9',
            contrastText: 'white',
        },
        secondary: {
            main: '#b4b4b4',
        }
    },
    MuiCssBaseline: {
        '@global': {
            body: {
                backgroundColor: 'white',
            },
        },
    },
    overrides: {
        MuiDialogTitle: {
            root: {
                backgroundColor: '#21b7e9',
                color: 'white',
                '& .MuiIconButton-root': {
                    color: 'white',
                },
            },
        },
    },
});

const AppBar = withStyles({
    root: {
        background: 'none',
        boxShadow: 'none',
        color: 'black',
        borderBottom: '1px solid #ccc',
    },
})(MuiAppBar);

export let SERVER_URL = 'ws://192.168.137.1:8080';
// export let SERVER_URL = 'wss://core.dynexus.es:8080';
console.log('Server URL', SERVER_URL);

function MainAppBar({title, styleName, onBack, isConnected}) {
    const classes = useGlobalStyles();
    const { t } = useTranslation();

    return (
        <AppBar position="static">
            <Toolbar>
                {onBack &&
                <IconButton
                    edge="start"
                    className={classes.backButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={onBack}
                >
                    <ChevronLeftIcon />
                </IconButton>}
                <Typography variant="h6" className={classes.title}>
                    {title}
                </Typography>
                <Typography className={clsx(classes.connectionStatus, isConnected ? 'connected' : 'disconnected')}>
                    {isConnected ?
                        <><WifiIcon /> {t('appbar.connected')}</>
                        :
                        <><WifiOffIcon /> {t('appbar.disconnected')}</>
                    }
                </Typography>
                {styleName &&
                <img src={`/${styleName}-logo.png`} alt={styleName} style={{height: 30}} />}
                <MainSettings />
            </Toolbar>
        </AppBar>
    );
}

function MainApp() {
    const [isConnected, setIsConnected] = useState(false);
    const classes = useGlobalStyles();
    const history = useHistory();
    const { t, i18n } = useTranslation();
    useNoSleep(true);
    const sessionWebsocketRef = useRef();
    const [styleName, setStyleName] = useState();

    const sendMessage = (socket, type, data) => {
        const message = { type };
        if (data !== undefined) {
            message.data = data;
        }

        try {
            socket.sendMessage(JSON.stringify(message));
        } catch (err) {

        }
    };

    const sendMessageToSessionSocket = useCallback((type, data) => {
        if (!sessionWebsocketRef.current)
            return;

        sendMessage(sessionWebsocketRef.current, type, data)
    }, [sessionWebsocketRef.current]);

    function handleData(msg) {

        msg = JSON.parse(msg);

        if (msg.type === 'state-change') {
            console.log(msg.data);
            const language = msg.data.language;
            if (language !== i18n.language)
                i18n.changeLanguage(language);

            if (styleName !== msg.data.styleName) {
                setStyleName(msg.data.styleName);
                i18n.styleName = msg.data.styleName;
            }
        }
    }

    function handleOpenSessionSocket() {
        setIsConnected(true);
        setInterval(() => sendMessageToSessionSocket('PING'), 500);
    }

    function handleCloseSessionSocket() {
        setIsConnected(false);
    }

    return (
        <>
            <Websocket
                url={`${SERVER_URL}/sessions`}
                onMessage={handleData}
                onOpen={handleOpenSessionSocket}
                onClose={handleCloseSessionSocket}
                reconnect={true}
                ref={sessionWebsocketRef}
            />
            <Switch>
                <Route
                    path='/playback/:sessionName/:recordingName'
                    render={({match: {params: {sessionName}}}) => (
                        <>
                            <MainAppBar
                                title={t('appbar.playback-title')}
                                styleName={styleName}
                                onBack={() => history.push(`/${sessionName}`)}
                                isConnected={isConnected}
                            />
                            <PlaybackView />
                        </>
                    )}
                >
                </Route>
                <Route
                    path='/record/:sessionName'
                    render={({match: {params: {sessionName}}}) => {
                        const sessionInfo = history.location.state.info;

                        return (
                            <>
                                <MainAppBar
                                    title={`${t('appbar.record-title')} ${sessionInfo.paciente}`}
                                    styleName={styleName}
                                    onBack={() => history.push(`/${sessionName}`)}
                                    isConnected={isConnected}
                                />
                                <RecordingView />
                            </>
                        )
                    }}
                >
                </Route>
                <Route
                    path='/:sessionName?'
                    render={({match: {params: {sessionName}}}) => (
                        <>
                            <MainAppBar
                                title={t('appbar.sesiones-title')}
                                styleName={styleName}
                                isConnected={isConnected}
                            />
                            <SessionBrowser sessionName={sessionName || null} />
                        </>
                    )}
                >
                </Route>
            </Switch>
        </>
    );
}

function createWebsocket(url) {
    const websocket = window.WebSocket
        ? new window.WebSocket(url)
        : new window.MozWebSocket(url);

    return websocket;
}

export default function App() {
    const classes = useGlobalStyles();
    const [protocol, setProtocol] = useState(false);

    useEffect(() => {
        const baseUrl = SERVER_URL.replace(/^wss?:\/\//, '');

        let ws= null;

        function tryConnectWs(baseUrl, useSecure = false) {
            const protocol = useSecure ? 'wss://' : 'ws://';
            const url = protocol + baseUrl + '/sessions';
            console.log('Testing protocol', url);
            ws = createWebsocket(url);
            ws.onopen = () => {
                console.log('Connection opened', url);
                ws.close();
                SERVER_URL = protocol + baseUrl;
                setProtocol(true);
                clearTimeout(timeoutId);
            };

            const timeoutId = setTimeout(() => {
                if (ws.readyState !== 1) {
                    console.log(`Connection to ${useSecure ? 'WSS' : 'WS'} failed, changing protocol....`);
                    ws.close();
                    tryConnectWs(baseUrl, !useSecure);
                }
            }, 2000);
        }

        tryConnectWs(baseUrl);
    }, []);

    if (!protocol)
        return null;

    return (
        <Suspense fallback="loading">
            <ThemeProvider theme={theme}>
                <SnackbarProvider SnackbarProps={{ autoHideDuration: 10000 }}>
                    <ErrorDialogProvider>
                        <Router>
                            <div className={classes.root}>
                                <CssBaseline />
                                <MainApp />
                            </div>
                        </Router>
                    </ErrorDialogProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </Suspense>
    );
}