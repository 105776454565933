import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 24 24" fill="inherit" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.8144 9.3493C18.8144 16.7986 10.6945 23.6976 10.6945 23.6976C10.22 24.1008 9.44353 24.1008 8.96907 23.6976C8.96907 23.6976 0.849121 16.7986 0.849121 9.3493C0.849211 4.21136 4.87086 0.0462646 9.83179 0.0462646C14.7927 0.0462646 18.8144 4.21136 18.8144 9.3493Z" />
            <path d="M7.83547 7.32379C7.28425 7.32379 6.8374 7.73341 6.8374 8.23869V12.8132C6.8374 13.3185 7.28425 13.7281 7.83547 13.7281C8.38669 13.7281 8.83355 13.3185 8.83355 12.8132V8.23869C8.83355 7.73341 8.38669 7.32379 7.83547 7.32379Z" fill="white"/>
            <path d="M11.8278 7.32379C11.2765 7.32379 10.8297 7.73341 10.8297 8.23869V12.8132C10.8297 13.3185 11.2765 13.7281 11.8278 13.7281C12.379 13.7281 12.8258 13.3185 12.8258 12.8132V8.23869C12.8258 7.73341 12.379 7.32379 11.8278 7.32379Z" fill="white"/>
        </svg>
    </SvgIcon>
)