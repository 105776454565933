import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import BackIcon from '@material-ui/icons/ArrowBackIos';
import DeleteIcon from "@material-ui/icons/Delete";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import VideocamIcon from '@material-ui/icons/Videocam';
import InfoIcon from '@material-ui/icons/Info';
import ReplayIcon from '@material-ui/icons/Replay';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Moment from "react-moment";
import CardMedia from "@material-ui/core/CardMedia";
import Websocket from "react-websocket";
import NewSessionDialog from "./NewSessionDialog";
import DeleteDialog from "../common/DeleteDialog";
import moment from "moment";
import {useHistory} from "react-router-dom";
import {SERVER_URL} from "../../App";
import {blue} from "@material-ui/core/colors";
import TableContainer from "@material-ui/core/TableContainer";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useTranslation} from "react-i18next";
import GenerateReportDialog from "./GenerateReportDialog";
import AssignProtocolDialog from "../recording/AssignProtocolDialog";
import SpeedometerIcon from "../../icons/SpeedometerIcon";
import BarefootIcon from "../../icons/BarefootIcon";
import Checkbox from "@material-ui/core/Checkbox";
import {useSnackbar} from "material-ui-snackbar-provider";
import EditPatientInfoDialog from "./EditPatientInfoDialog";
import useErrorDialog from "../ErrorDialogProvider/useErrorDialog";
import Divider from "@material-ui/core/Divider";
import Chip from '@material-ui/core/Chip';
import UnarchiveSessionDialog from "./UnarchiveSessionDialog";
import ValidateSessionDialog from "./ValidateSessionDialog";


const useStyles = makeStyles(theme => ({
    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        height: `calc(100% - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight + 1 + theme.spacing(4)}px)`,
        padding: theme.spacing(2),
        gap: `${theme.spacing(2)}px`,
        flex: 1,
    },
    loading: {
        fontSize: 24,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: `${theme.spacing(3)}px`,
    },
    card: {
        // width: '48%',
        // height: 400,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        // flex: 1,
        // margin: 8,
    },
    cardHeader: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiTypography-root': {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
        },
    },
    sessionActions: {
        marginLeft: theme.spacing(3),
    },
    cardContent: {
        flex: 1,
        overflowX: 'hidden',
    },
    cardActions: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    emptyContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        margin: 'auto',
        textAlign: 'center',
        fontSize: 18,
    },
    patientInfoMissing: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 18px',
        opacity: 0.6,
        '& svg': {
            width: 20,
            height: 20,
            marginRight: 4,
        },
    },
    tableContainer: {
        overflowX: 'initial',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    },
    table: {
        '& .MuiTableCell-sizeSmall': {
            padding: 6,
        },
        '& .MuiTableCell-sizeSmall:last-of-type, & .MuiTableCell-sizeSmall:first-of-type': {
            width: '1%',
            whiteSpace: 'nowrap',
        },
    },
    sessionsTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    sessionsTableHeader: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    sessionsSelectedRow: {
        backgroundColor: blue[100],
    },
    miniIcon: {
        color: theme.palette.secondary.main,
        height: 18,
        marginBottom: -4,
    },
    recordingNameCell: {
        whiteSpace: 'nowrap',
    },
    patientCell: {
        display: 'flex',
        alignItems: 'center',
        '&>span:first-of-type': {
            flex: 1,
        },
    },
    chip: {
        borderRadius: 1000,
        border: '1px solid #ccc',
        padding: theme.spacing(0.5, 1.5),
        fontSize: 14,
        marginLeft: theme.spacing(2),
    },
}));

export default function SessionBrowser({sessionName}) {
    const classes = useStyles();
    const [selectedSession, setSelectedSession] = useState(null);
    const [sessions, setSessions] = useState(null);
    const [savingId, setSavingId] = useState(null);
    const [generatingReport, setGeneratingReport] = useState(false);
    // const [protocols, setProtocols] = useState([]);
    const [selectedRecordings, setSelectedRecordings] = useState([]);
    const [viewingReport, setViewingReport] = useState(false);
    // const snackbar = useSnackbar();
    const errorDialog = useErrorDialog();

    const history = useHistory();
    const websocketRef = useRef();
    const { t, i18n: {language} } = useTranslation();


    const [messageResponseHandlers, setMessageResponseHandlers] = useState({});

    const dateFormat = language === 'en' ? 'MM-DD-YYYY | HH:mm' : 'DD-MM-YYYY | HH:mm';

    const handleData = msg => {
        msg = JSON.parse(msg);

        if (msg.type === 'sessions') {
            setSessions(msg.data);
        // } else if (msg.type === 'protocols') {
        //     setProtocols(msg.data);
        } else if (msg.type === 'report-finished') {
            setGeneratingReport(false);
        } else if (msg.type === 'sync-finished') {
            setGeneratingReport(false);
            setSelectedSession(null);
            history.push('/');
            setSessions(null);
            sendMessage('GET_SESSIONS');
        } else if (msg.type === 'state-change') {
            const {savingStatus, viewerMode, message} = msg.data;

            setViewingReport(viewerMode === 'REPORT');

            // if (savingStatus === 'PROCESSING') {
            //     setTimeout(() => {
            //         sendMessage('GET_SAVING_STATUS');
            //     }, 500);
            // } else
            if (savingStatus === 'DONE') {
                setSavingId(null);
                sendMessage('GET_SESSIONS');
                // } else if (savingStatus === 'ERROR') {
                //     setSavingId(null);
                //     snackbar.showMessage(t(message));
            }
        } else if (msg.type === 'report-error') {
            errorDialog.showMessage('No se ha podido visualizar el informe. Vuelve a reprocesar la grabación.');
        } else if (msg.type === 'error') {
            errorDialog.showMessage(msg.data);
            setGeneratingReport(false);
            setSavingId(null);
            sendMessage('GET_SESSIONS');
        }

        const handler = messageResponseHandlers[msg.type];
        if (handler) {
            handler(msg.data);
            setMessageResponseHandlers(({[msg.type]: _, ...rest}) => rest);
        }
    };

    const handleOpen = () =>  {
        console.log("Connected to sessions WS");
        sendMessage('GET_SESSIONS');
        sendMessage('ENABLE_SPLASH');
        sendMessage('GET_PROTOCOLS');
    };

    const handleClose = () => {
        console.log("Disconnected from sessions WS");
    };

    const handleError = (e) => {
        console.error(e);
    };

    const sendMessage = useCallback((type, data) => {
        if (!websocketRef.current) {
            console.warn('Websocket not ready');
            return;
        }

        const message = { type };
        if (data !== undefined) {
            message.data = data;
        }
        websocketRef.current.sendMessage(JSON.stringify(message));
    }, [websocketRef]);

    const postMessage = useCallback(function ({type, data, responseType, responseCallback}) {
        setMessageResponseHandlers(handlers => ({
            ...handlers,
            [responseType]: responseCallback,
        }));
        sendMessage(type, data);
    }, [sendMessage]);

    useEffect(() => {
        if (!sessions)
            return;

        if (sessionName) {
            const idx = sessions.findIndex(session => session.id === sessionName);
            if (idx < 0)
                history.replace('/');
            else
                setSelectedSession(idx);
        } else {
            setSelectedSession(null);
        }

    }, [history, sessions, sessionName]);

    const selectSession = i => {
        if (i == null) {
            history.push('/');
        } else {
            history.push(`/${sessions[i].id}`);
        }
    };

    const createSession = newSessionInfo => {
        sendMessage('CREATE_SESSION', JSON.stringify(newSessionInfo));
    };

    const addRecording = () => {
        var session = sessions[selectedSession];
        history.push(`/record/${session.id}`, {info: session.info});
    };

    const deleteSession = sessionId => {
        sendMessage('DELETE_SESSION', sessionId);
        history.replace('/');
    };

    const getRecordingPath = i => {
        const date = moment(sessions[selectedSession].grabaciones[i].fecha).format('YYYYMMDD_HHmmss');
        return `${sessions[selectedSession].id}/${date}`;
    };

    const getRecordingPathForRecording = recording => {
        const date = moment(recording.fecha).format('YYYYMMDD_HHmmss');
        return `${sessions[selectedSession].id}/${date}`;
    };

    const deleteRecording = i => {
        const recordingPath = getRecordingPath(i);
        sendMessage('DELETE_RECORDING', recordingPath);
    };

    const rewriteRecording = i => {
        const recordingPath = getRecordingPath(i);
        setSavingId(i);
        sendMessage('REWRITE_RECORDING', recordingPath);
    };

    const generateReport = (data, otherParams) => {
        const recordingPaths =
            sessions[selectedSession].grabaciones
                .map((g, i) => ({
                    key: g[recordingIdKey],
                    path: getRecordingPath(i),
                }))
                .filter(r => selectedRecordings.includes(r.key))
                .map(r => r.path);

        const reportParams = {
            paths: recordingPaths,
            ...otherParams,
        };

        if (data)
            reportParams.header = data;

        setGeneratingReport(true);
        sendMessage('GENERATE_REPORT', JSON.stringify(reportParams));
    }

    function validateSession({selectedRecordings, pedirPlantillas, material, address, phone, email}) {
        const recordingPaths = selectedRecordings.map(getRecordingPathForRecording);

        const syncParams = {
            sessionName: sessions[selectedSession].id,
            paths: recordingPaths,
            header: {
                ...sessions[selectedSession].info.patientInfo,
                address,
                phone,
                email,
            },
            pedirPlantillas,
            material,
        };

        sendMessage('VALIDATE_SESSION', JSON.stringify(syncParams));
        setGeneratingReport(true);
    }

    function syncSession() {
        const recordingPaths =
            sessions[selectedSession].grabaciones
                .map((g, i) => ({
                    key: g[recordingIdKey],
                    path: getRecordingPath(i),
                }))
                .filter(r => selectedRecordings.includes(r.key))
                .map(r => r.path);

        const syncParams = {
            sessionName: sessions[selectedSession].id,
            paths: recordingPaths,
            comment: sessions[selectedSession].info.patientInfo.observations,
        };

        sendMessage('SYNC_SESSION', JSON.stringify(syncParams));
        setGeneratingReport(true);
    }

    const updateRecordingInfo = i => values => {
        const route = getRecordingPath(i);
        sendMessage('UPDATE_RECORDING_INFO', JSON.stringify({
            route,
            ...values,
        }));
    };

    const openBodyStatisticsReport = i => () => {
        const route = getRecordingPath(i);
        sendMessage('ENABLE_BODY_STATISTICS', route);
    };

    const openFootStatisticsReport = i => () => {
        const route = getRecordingPath(i);
        sendMessage('ENABLE_FOOT_STATISTICS', route);
    };

    const unarchiveSession = selectedItem => {
        sendMessage('UNARCHIVE_SESSION', selectedItem.sesionId);
        setSessions(null);
    };

    const recordingIdKey = 'fecha';

    function selectRecording(ev) {
        const id = ev.target.value;

        if (ev.target.checked)
            setSelectedRecordings(recordings => [...recordings, id]);
        else
            setSelectedRecordings(recordings => recordings.filter(r => r !== id))
    }

    const selectableRecordings = useMemo(() => {
        if (selectedSession === null)
            return null;

        return sessions[selectedSession]
            .grabaciones
            .filter(g => g.hasPressures && g.duracion !== null)
            .map(g => g[recordingIdKey]);
    }, [sessions, selectedSession]);
    const allRecordingsSelected = selectedSession !== null && selectableRecordings.length > 0 && selectedRecordings.length === selectableRecordings.length;

    function selectAllRecordings(ev) {
        if (ev.target.checked) {
            setSelectedRecordings(selectableRecordings);
        } else {
            setSelectedRecordings([]);
        }
    }

    return (
        <>
            <Websocket
                url={`${SERVER_URL}/sessions`}
                onMessage={handleData}
                onOpen={handleOpen}
                onClose={handleClose}
                onError={handleError}
                reconnect={true} debug={true}
                reconnectIntervalInMilliSeconds={10000}
                ref={websocketRef}
            />
            <div
                className={classes.cardContainer}
            >
                {sessions === null ?
                    <div className={classes.loading}>
                        <CircularProgress
                            size={42}
                            style={{marginRight: 8}}
                        />
                        {t('SessionBrowser.loading')}
                    </div>
                    :
                    <>
                        {selectedSession === null &&
                        <Card className={classes.card}>
                            <CardContent className={classes.sessionsTitle}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {t('SessionBrowser.sessions-table.title')}
                                </Typography>
                            </CardContent>
                            <CardMedia className={classes.cardContent}>
                                <TableContainer className={classes.tableContainer}>
                                    <Table stickyHeader className={classes.table} size="small" aria-label="sessions">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    className={classes.sessionsTableHeader}
                                                >
                                                    {t('SessionBrowser.sessions-table.patient')}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.sessionsTableHeader}
                                                >
                                                    {t('SessionBrowser.sessions-table.date')}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sessions.map((row, i) => (
                                                <TableRow
                                                    key={row.id}
                                                    className={selectedSession === i ? classes.sessionsSelectedRow : null}
                                                    onClick={() => selectSession(selectedSession !== i ? i : null)}
                                                >
                                                    <TableCell component="th" scope="row" style={{height: 44}}>
                                                        <div className={classes.patientCell}>
                                                            <span>{row.info.paciente}</span>
                                                            {row.revision > 0 &&
                                                            <span className={classes.chip}>rev {row.revision}</span>}
                                                        </div>
                                                    </TableCell>

                                                    {selectedSession === null &&
                                                    <>
                                                        <TableCell>
                                                            <Moment format={dateFormat}>{row.info.fecha}</Moment>
                                                        </TableCell>
                                                    </>}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardMedia>
                            <CardActions className={classes.cardActions}>
                                <UnarchiveSessionDialog
                                    onSave={unarchiveSession}
                                    component={
                                        <Button size="small" color="primary" variant="contained">
                                            {t('SessionBrowser.sessions-table.unarchive-session')} <ChevronRightIcon/>
                                        </Button>
                                    }
                                    postMessage={postMessage}
                                />
                                <NewSessionDialog
                                    onSave={createSession}
                                    component={
                                        <Button size="small" color="primary" variant="contained">
                                            {t('SessionBrowser.sessions-table.new-session')} <ChevronRightIcon/>
                                        </Button>
                                    }
                                    postMessage={postMessage}
                                />
                            </CardActions>
                        </Card>}

                        <Card className={classes.card} style={{flex: 1}}>
                            {selectedSession !== null &&
                            <CardContent className={classes.cardHeader}>
                                <IconButton
                                    onClick={() => selectSession(null)}
                                >
                                    <BackIcon />
                                </IconButton>
                                <Typography variant="h5" component="h2">
                                    {sessions[selectedSession].info.paciente}
                                    {sessions[selectedSession].revision > 0 &&
                                    <span className={classes.chip}>Revision {sessions[selectedSession].revision}</span>}
                                </Typography>
                                <Moment format={dateFormat}>{sessions[selectedSession].info.fecha}</Moment>
                                <div className={classes.sessionActions}>
                                    <EditPatientInfoDialog
                                        component={
                                            <IconButton>
                                                <EditIcon/>
                                            </IconButton>
                                        }
                                        defaultValues={sessions[selectedSession].info.patientInfo}
                                        onSave={values => {
                                            sendMessage('UPDATE_SESSION_INFO', JSON.stringify({
                                                id: sessions[selectedSession].id,
                                                values,
                                            }));
                                        }}
                                    />
                                    <DeleteDialog
                                        component={
                                            <IconButton>
                                                <DeleteIcon/>
                                            </IconButton>
                                        }
                                        onDelete={() => deleteSession(sessions[selectedSession].id)}
                                        objectType={t('SessionBrowser.sessions-table.delete-objectType')}
                                    />
                                </div>
                            </CardContent>}
                            <CardMedia className={classes.cardContent}>
                                {selectedSession !== null ?
                                    <Table stickyHeader className={classes.table} size="small" aria-label="sessions">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    {selectedSession != null &&
                                                    <Checkbox
                                                        color='primary'
                                                        checked={allRecordingsSelected}
                                                        onChange={selectAllRecordings}
                                                    />}
                                                </TableCell>
                                                <TableCell>{t('SessionBrowser.recordings-table.time')}</TableCell>
                                                <TableCell>{t('SessionBrowser.recordings-table.protocol')}</TableCell>
                                                <TableCell>{t('SessionBrowser.recordings-table.duration')}</TableCell>
                                                <TableCell />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sessions[selectedSession].grabaciones.length === 0 ?
                                                <TableRow>
                                                    <TableCell colSpan={4}>
                                                        {t('SessionBrowser.recordings-table.no-recordings')}
                                                    </TableCell>
                                                </TableRow>
                                                :
                                                sessions[selectedSession].grabaciones.map((row, i) => {
                                                    let protocolName = '';

                                                    if (row.protocolo)
                                                        protocolName = row.protocolo.nombre
                                                    else if (row.info && row.info.customName)
                                                        protocolName = row.info.customName;
                                                    else
                                                        protocolName = 'Manual';

                                                    if (row.info && (row.info.protocolId > 0 || row.info.customName) && row.info.isManual)
                                                        protocolName = `${protocolName} (M)`;

                                                    const value = row[recordingIdKey];
                                                    const checked = selectedRecordings.includes(value)

                                                    return (
                                                        <TableRow key={row.fecha}>
                                                            <TableCell>
                                                                {row.hasPressures && row.duracion !== null &&
                                                                <Checkbox
                                                                    color='primary'
                                                                    checked={checked}
                                                                    value={value}
                                                                    onChange={selectRecording}
                                                                />}
                                                            </TableCell>
                                                            <TableCell><Moment format='HH:mm'>{row.fecha}</Moment></TableCell>
                                                            <TableCell>
                                                                {protocolName}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.duracion !== null && <><VideocamIcon className={classes.miniIcon} /> {row.duracion}</>}
                                                            </TableCell>
                                                            <TableCell>
                                                                <div style={{display: 'flex'}}>
                                                                    {row.hasPressures &&
                                                                    <>
                                                                        {row.duracion === null ?
                                                                            <div style={{position:'relative'}}>
                                                                                <IconButton
                                                                                    size="small"
                                                                                    onClick={() => rewriteRecording(i)}
                                                                                    disabled={savingId !== null || generatingReport}
                                                                                >
                                                                                    <DoneIcon />
                                                                                </IconButton>
                                                                                {savingId === i &&
                                                                                <CircularProgress
                                                                                    size={30}
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: 0,
                                                                                        left: 0,
                                                                                    }}
                                                                                />}
                                                                            </div>
                                                                            :
                                                                            <>
                                                                                <IconButton
                                                                                    size="small"
                                                                                    onClick={() => history.push(`/playback/${getRecordingPath(i)}`)}
                                                                                    disabled={savingId !== null || generatingReport || sessions[selectedSession].info.patientInfo === null}
                                                                                >
                                                                                    <PlayArrowIcon />
                                                                                </IconButton>
                                                                                <IconButton
                                                                                    size="small"
                                                                                    onClick={openBodyStatisticsReport(i)}
                                                                                    disabled={savingId !== null || generatingReport || sessions[selectedSession].info.patientInfo === null}
                                                                                >
                                                                                    <SpeedometerIcon />
                                                                                </IconButton>
                                                                                <IconButton
                                                                                    size="small"
                                                                                    onClick={openFootStatisticsReport(i)}
                                                                                    disabled={savingId !== null || generatingReport || sessions[selectedSession].info.patientInfo === null}
                                                                                >
                                                                                    <BarefootIcon />
                                                                                </IconButton>
                                                                            </>
                                                                        }
                                                                        {row.info && row.info.isManual &&
                                                                        <AssignProtocolDialog
                                                                            component={
                                                                                <IconButton
                                                                                    size='small'
                                                                                    disabled={savingId !== null || generatingReport}
                                                                                >
                                                                                    <EditIcon />
                                                                                </IconButton>
                                                                            }
                                                                            enableCustomName
                                                                            postMessage={postMessage}
                                                                            onSelect={updateRecordingInfo(i)}
                                                                        />}
                                                                        {row.duracion !== null &&
                                                                        <div style={{position:'relative'}}>
                                                                            <IconButton
                                                                                size="small"
                                                                                onClick={() => rewriteRecording(i)}
                                                                                disabled={savingId !== null || generatingReport}
                                                                            >
                                                                                <ReplayIcon />
                                                                            </IconButton>
                                                                            {savingId === i &&
                                                                            <CircularProgress
                                                                                size={30}
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    top: 0,
                                                                                    left: 0,
                                                                                }}
                                                                            />}
                                                                        </div>}
                                                                    </>}
                                                                    <DeleteDialog
                                                                        component={
                                                                            <IconButton
                                                                                size="small"
                                                                                disabled={savingId !== null || generatingReport}
                                                                            >
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        }
                                                                        onDelete={() => deleteRecording(i)}
                                                                        objectType={t('SessionBrowser.recordings-table.delete-objectType')}
                                                                    />
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                        </TableBody>
                                    </Table>
                                    :
                                    <div className={classes.emptyContent}>
                                        {t('SessionBrowser.recordings-table.no-session-selected')}
                                    </div>
                                }
                            </CardMedia>
                            <Divider style={{width: '100%'}} />
                            {selectedSession !== null && sessions[selectedSession].info.patientInfo === null &&
                            <div className={classes.patientInfoMissing}>
                                <InfoIcon /> {t('SessionBrowser.recordings-table.patient-info-missing')}
                            </div>}
                            {selectedSession !== null &&
                            sessions[selectedSession].info.patientInfo !== null &&
                            (
                                sessions[selectedSession].info.patientInfo.observations === null ||
                                sessions[selectedSession].info.patientInfo.observations.trim() === '' ||
                                sessions[selectedSession].info.patientInfo.feetNumber === null ||
                                sessions[selectedSession].info.patientInfo.feetNumber.trim() === ''
                            ) &&
                            <div className={classes.patientInfoMissing}>
                                <InfoIcon /> {t('SessionBrowser.recordings-table.observations-missing')}
                            </div>}
                            {viewingReport &&
                            <div className={classes.patientInfoMissing}>
                                <InfoIcon /> {t('SessionBrowser.recordings-table.viewing-report')}
                                <Button
                                    color='primary'
                                    onClick={() => sendMessage('ENABLE_SPLASH')}
                                >
                                    {t('SessionBrowser.recordings-table.viewing-report-disable')}
                                </Button>
                            </div>}
                            <CardActions className={classes.cardActions}>
                                {selectedSession !== null &&
                                <>
                                    {/*<Button*/}
                                    {/*    size="small"*/}
                                    {/*    color="primary"*/}
                                    {/*    variant="contained"*/}
                                    {/*    disabled={*/}
                                    {/*        generatingReport ||*/}
                                    {/*        sessions[selectedSession].info.patientInfo === null ||*/}
                                    {/*        sessions[selectedSession].info.patientInfo.observations === null ||*/}
                                    {/*        sessions[selectedSession].info.patientInfo.observations.trim() === '' ||*/}
                                    {/*        sessions[selectedSession].grabaciones.length === 0 ||*/}
                                    {/*        selectedRecordings.length === 0*/}
                                    {/*    }*/}
                                    {/*    onClick={syncSession}*/}
                                    {/*>*/}
                                    {/*    {t('SessionBrowser.recordings-table.sync-session')}*/}
                                    {/*</Button>*/}
                                    <ValidateSessionDialog
                                        component={
                                            <Button
                                                size="small"
                                                color="primary"
                                                variant="contained"
                                                disabled={
                                                    generatingReport ||
                                                    sessions[selectedSession].info.patientInfo === null ||
                                                    sessions[selectedSession].info.patientInfo.observations === null ||
                                                    sessions[selectedSession].info.patientInfo.observations.trim() === '' ||
                                                    sessions[selectedSession].grabaciones.length === 0
                                                }
                                            >
                                                {t('SessionBrowser.recordings-table.validate-session')}
                                            </Button>
                                        }
                                        onSave={validateSession}
                                        selectedRecordings={sessions[selectedSession].grabaciones.filter(row => row.hasPressures && row.duracion !== null)}
                                        canPedirPlantillas={sessions[selectedSession].id.length >= 9}
                                    />
                                    <GenerateReportDialog
                                        component={
                                            <Button
                                                size="small"
                                                color="primary"
                                                variant="contained"
                                                disabled={
                                                    generatingReport ||
                                                    sessions[selectedSession].info.patientInfo === null ||
                                                    sessions[selectedSession].grabaciones.length === 0 ||
                                                    selectedRecordings.length === 0
                                                }
                                            >
                                                {t('SessionBrowser.recordings-table.generate-report')}
                                            </Button>
                                        }
                                        onSave={values => {
                                            generateReport(sessions[selectedSession].info.patientInfo, values)
                                        }}
                                    />
                                    <Button
                                        size="small"
                                        color="primary"
                                        variant="contained"
                                        onClick={() => addRecording()}
                                        disabled={generatingReport}
                                    >
                                        {t('SessionBrowser.recordings-table.new-recording')} <ChevronRightIcon/>
                                    </Button>
                                </>}
                            </CardActions>
                        </Card>
                    </>
                }
            </div>
        </>
    );
}