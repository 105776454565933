import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useCallbackRef} from "use-callback-ref";
import {makeStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {fmtNum, splitMillisInParts} from "../recording/Timer";
import clsx from "clsx";
import Websocket from "react-websocket";
import {SERVER_URL} from "../../App";
import {useParams} from "react-router-dom";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import TableContainer from '@material-ui/core/TableContainer';
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocationOffIcon from '@material-ui/icons/LocationOff';
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import Moment from "react-moment";
import SettingsIcon from "@material-ui/icons/Settings";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import RestoreIcon from '@material-ui/icons/Restore';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import {blockedAngles, measureKeys, measureLabelsLocalized} from "../../constants";
import MeasurementSettingsDialog from "../common/MeasurementSettingsDialog";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import TimeLine from "./TimeLine";
import PauseOnKeypointsIcon from "../../icons/PauseOnKeypointsIcon";
import PreviousKeypointIcon from "../../icons/PreviousKeypointIcon";
import NextKeypointIcon from "../../icons/NextKeypointIcon";
import {useTranslation} from "react-i18next";

const urlCreator = window.URL || window.webkitURL;

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'calc(100% - 65px)',
        justifyContent: 'center',
    },
    container: {
        flex: 1,
        display: 'flex',
        overflow: 'hidden',
        padding: theme.spacing(2),
        gap: `${theme.spacing(2)}px`,
        width: '100%',
    },
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: 65,
        alignItems: 'center',
    },
    button: {
        height: '100%',
        width: 75,
        borderRadius: 0,
    },
    buttonEnabled: {
        backgroundColor: '#ccc',
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: '#ccc',
        },
    },
    buttonSpeed: {
        '& .MuiButton-label': {
            position: 'relative',
            left: -10,
        },
    },
    buttonSpeedFraction: {
        fontSize: '67%',
        position: 'absolute',
        bottom: -4,
        right: 4,
    },
    timeDisplay: {
        fontSize: 24,
        fontWeight: 'bold',
        margin: theme.spacing(0, 2),
    },
    tableContainer: {
        // height: 200,
        // marginTop: theme.spacing(2),
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    },
    measurements: {
        // margin: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        '&:first-of-type': {
            flex: 1,
        },
    },
    measurementsTitle: {
        margin: theme.spacing(1, 2),
    },
    buttonsCell: {
        paddingTop: 0,
        paddingBottom: 0,
    },
}));


const PAUSED = 0,
    FORWARD = 1,
    BACKWARD = 2;


export default function PlaybackView() {
    const classes = useStyles();
    const videoRef = useCallbackRef(null, video => {
        if (!video)
            return;

        video.skeletons = [];
        video.angles = [];
        video.direction = null;

        video.addEventListener('loadeddata', (v) => {
            setLoading(false);
        }, false);
    });

    const sessionWebsocketRef = useRef();
    const [state, setState] = useState({
        skeletonEnabled: false,
        kinectEnabled: false,
        enabledAngles: {},
        recording: false,
        isTracking: false,
        savingStatus: '',
        recordingRoute: null,
    });
    const [loading, setLoading] = useState(true);
    const [currentTime, setCurrentTime] = useState(0);
    const [playbackState, setPlaybackState] = useState(PAUSED);
    const [playbackSpeed, setPlaybackSpeed] = useState(1);
    const [stopAtKeypoints, setStopAtKeypoints] = useState(false);
    const [angleData, setAngleData] = useState(null);
    const [screenshotTimes, setScreenshotTimes] = useState([]);
    const {sessionName, recordingName} = useParams();
    const [enabledKeyPoints, setEnabledKeyPoints] = useState([]);
    const { t, i18n: {language} } = useTranslation();

    const videoEl = videoRef.current;

    useEffect(() => {
        let sign = 0;
        if (playbackState === FORWARD)
            sign = 1;
        else if (playbackState === BACKWARD)
            sign = -1;

        const speed = playbackSpeed * sign;

        sendMessageToSessionSocket('SET_PLAYBACK_SPEED', speed.toString());
    }, [playbackState, playbackSpeed]);

    const setVideoCurrentTime = t => {
        if (!videoEl)
            return;

        videoEl.currentTime = t;
        updateNextKeyPointIndex();
    };

    const updateNextKeyPointIndex = () => {
        for (let i=0; i<videoEl.keyPoints.length; i++) {
            if (videoEl.currentTime <= videoEl.keyPoints[i]) {
                videoEl.nextKeyPointIndex = i;
                break;
            }
        }

        if (videoEl.nextKeyPointIndex === videoEl.keyPoints.length)
            videoEl.nextKeyPointIndex = null;
    };

    const [gotoNextKeyPoint, gotoPreviousKeyPoint] = function() {
        function moveKeyPoint(forward) {
            if (videoEl === null || !videoEl.nextKeyPointIndex)
                return;

            updateNextKeyPointIndex();

            if (forward) {
                if (Math.abs(videoEl.currentTime - videoEl.keyPoints[videoEl.nextKeyPointIndex]) < 1e-3)
                    videoEl.nextKeyPointIndex += 1;

                videoEl.currentTime = videoEl.keyPoints[videoEl.nextKeyPointIndex];
                videoEl.nextKeyPointIndex += 1;
            } else {
                videoEl.currentTime = videoEl.keyPoints[videoEl.nextKeyPointIndex - 1];
                videoEl.nextKeyPointIndex -= 1;
            }
        }

        return [
            () => moveKeyPoint(true),
            () => moveKeyPoint(false),
        ];
    }();

    useEffect(() => {
        if (videoEl === null)
            return;

        const keyPoints = enabledKeyPoints
            .map(key => angleData.keyPoints[key])
            .flat()
            .sort((a, b) => a - b);

        videoEl.keyPoints = keyPoints;
        updateNextKeyPointIndex();

        const interval = setInterval(() => {
            const videoEl = videoRef.current;

            if (!videoEl)
                return;

            if (videoEl.currentTime === videoEl.duration)
                setPlaybackState(PAUSED);

            if (stopAtKeypoints &&
                videoEl.nextKeyPointIndex !== null &&
                videoEl.currentTime >= videoEl.keyPoints[videoEl.nextKeyPointIndex]) {
                setVideoCurrentTime(videoEl.keyPoints[videoEl.nextKeyPointIndex]);
                setPlaybackState(PAUSED);
                videoEl.nextKeyPointIndex++;
            }

            setCurrentTime(videoEl.currentTime);
        }, 10);

        return () => clearInterval(interval);
    }, [stopAtKeypoints, enabledKeyPoints, angleData]);

    useEffect(() => {
        if (!videoEl)
            return;

        if (playbackState === PAUSED) {
            videoEl.pause();
            return;
        }
        if (playbackState === FORWARD) {
            videoEl.play();
            return;
        }

        const frameTime = 0.05;
        videoEl.pause();

        const interval = setInterval(() => {
            if (videoEl.currentTime <= frameTime) {
                setVideoCurrentTime(0);
                setPlaybackState(PAUSED);
                clearInterval(interval);
            }
            videoEl.currentTime -= frameTime * videoEl.playbackRate;
        }, frameTime * 1000);

        return () => clearInterval(interval);
    }, [playbackState]);

    useEffect(() => {
        videoRef.current.playbackRate = playbackSpeed;
    }, [playbackSpeed]);

    const {
        hours,
        minutes,
        seconds,
        millis,
    } = useMemo(() => splitMillisInParts(currentTime * 1000), [currentTime]);

    const percentagePlayed = videoRef.current ? 100 * currentTime / videoRef.current.duration : 0;

    const togglePlayback = mode => () => setPlaybackState(state => state === mode ? PAUSED : mode);


    const handleOpenSessionSocket = () =>  {
        console.log("Session socket connected");
        sendMessageToSessionSocket('state');
        sendMessageToSessionSocket('ENABLE_PLAYBACK', `${sessionName}/${recordingName}`);
        sendMessageToSessionSocket('GET_VIDEO');
        sendMessageToSessionSocket('GET_ANGLES');
        sendMessageToSessionSocket('GET_SCREENSHOTS');
    };

    const handleCloseSessionSocket = () => {
        console.log("Session socket connected");
    };

    const sendMessageToSessionSocket = (type, data) => {
        if (!sessionWebsocketRef.current)
            return;

        sendMessage(sessionWebsocketRef.current, type, data)
    };

    const sendMessage = (socket, type, data) => {
        const message = { type };
        if (data !== undefined) {
            message.data = data;
        }

        try {
            socket.sendMessage(JSON.stringify(message));
        } catch (err) {

        }
    };

    const {
        enabledAngles,
    } = state;

    const updateAngleData = (angles) => {
        console.log('Updating angle stats and keypoints');

        const {measurementConfig} = state;

        const stats = {};
        const keyPoints = {};

        for (let key of measureKeys) {
            const {validMin, validMax} = measurementConfig[key];
            let min, max, avg;
            min = max = avg = angles[0][key];
            let numMinKeyPoints = 0,
                numMaxKeyPoints = 0;

            const startTime = angles[0].timestamp;
            const angleKeyPoints = [];
            let keyPointStart = null,
                keyPointIsMax;

            for (let i=1; i<angles.length; ++i) {
                const value = angles[i][key];
                min = Math.min(min, value);
                max = Math.max(max, value);
                avg += value;

                if (keyPointStart === null) {
                    if (value >= validMin && value <= validMax)
                        continue;

                    keyPointStart = angles[i].timestamp;
                    keyPointIsMax = value > validMax;
                } else {
                    if (value < validMin || value > validMax)
                        continue;

                    angleKeyPoints.push(keyPointStart + (angles[i].timestamp - keyPointStart) * 0.5 - startTime);
                    keyPointStart = null;

                    if (keyPointIsMax)
                        ++numMaxKeyPoints;
                    else
                        ++numMinKeyPoints;
                }
            }
            avg /= angles.length;

            stats[key] = {min, max, avg, numMinKeyPoints, numMaxKeyPoints};
            keyPoints[key] = angleKeyPoints;
        }

        setAngleData({angles, stats, keyPoints});
    };

    const handleData = msg => {
        if (msg instanceof Blob) {
            videoRef.current.src = urlCreator.createObjectURL(msg);
        } else {
            msg = JSON.parse(msg);

            if (msg.type === 'state-change') {
                setState(state => {
                    if (msg.data.recordingStartDate)
                        msg.data.recordingStartDate = new Date(msg.data.recordingStartDate);
                    return {
                        ...state,
                        ...msg.data
                    };
                });

                if (angleData && angleData.angles)
                    updateAngleData(angleData.angles);
            } else if (msg.type === 'angles') {
                updateAngleData(msg.data);
            } else if (msg.type === 'screenshot-list') {
                const start = moment(recordingName, 'YYYYMMDD_HHmmss');

                setScreenshotTimes(msg.data.map(t => ({
                    time: t,
                    date: start.add(t, 'seconds').toDate()
                })));
            } else {
                console.log(msg);
            }
        }
    };

    const frameNumber = videoRef.current && angleData ? Math.min(angleData.angles.length - 1, Math.floor(videoRef.current.currentTime * 30)) : 0;

    const toggleKeypoints = key => () => {
        setEnabledKeyPoints(keys => {
            const idx = keys.indexOf(key);
            if (idx < 0)
                return [...keys, key];

            const newKeys = [...keys];
            newKeys.splice(idx, 1);
            return newKeys;
        })
    };

    const toggleAngle = angle => () => {
        sendMessageToSessionSocket('TOGGLE_ANGLE' ,angle);
    };

    const allAnglesEnabled = useMemo(() => {
        return measureKeys
            .filter(k => !blockedAngles.includes(k))
            .every(k => enabledAngles[k]);
    }, [measureKeys, enabledAngles, blockedAngles]);

    const toggleAllAngles = () => {
        let nonBlockedAngles = measureKeys
            .filter(k => !blockedAngles.includes(k));

        if (!allAnglesEnabled)
            nonBlockedAngles = nonBlockedAngles
                .filter(k => !enabledAngles[k]);

        nonBlockedAngles.forEach(k => toggleAngle(k)());
    }

    return (
        <>
            <Websocket
                url={`${SERVER_URL}/sessions`}
                onMessage={handleData}
                onOpen={handleOpenSessionSocket} onClose={handleCloseSessionSocket}
                reconnect={true} debug={true}
                ref={sessionWebsocketRef}
            />
            <video ref={videoRef} style={{display: 'none'}} />
            <div
                className={classes.root}
            >
                {loading ?
                    <>
                        <CircularProgress size={80} />
                        <Typography
                            component='h4'
                            variant='h6'
                            style={{marginTop: 24}}
                        >
                            {t('PlaybackView.loading-data')}...
                        </Typography>
                    </>
                    :
                    <>
                        <div className={classes.container}>
                            <Paper
                                className={classes.measurements}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                    className={classes.measurementsTitle}
                                >
                                    <Typography variant="h5">{t('PlaybackView.measurements-table.title')}</Typography>
                                    <MeasurementSettingsDialog
                                        onSave={settings => {
                                            sendMessageToSessionSocket('CONFIGURE_LIMITS', JSON.stringify(settings));
                                        }}
                                        currentSettings={state.measurementConfig || {}}
                                        component={
                                            <IconButton size="small" edge="end" className={classes.settingsButton} color="inherit" aria-label="menu">
                                                <SettingsIcon />
                                            </IconButton>
                                        }
                                    />
                                </div>

                                <TableContainer className={classes.tableContainer}>
                                    <Table stickyHeader className={classes.table} size="small" aria-label="sessions">
                                        <TableHead className={classes.sessionsTableHeader}>
                                            <TableRow>
                                                <TableCell>
                                                    {/*<IconButton*/}
                                                    {/*    onClick={() => toggleAllAngles()}*/}
                                                    {/*    size='small'*/}
                                                    {/*>*/}
                                                    {/*    { allAnglesEnabled ? <VisibilityIcon fontSize='small' /> : <VisibilityOffIcon fontSize='small' />}*/}
                                                    {/*</IconButton>*/}
                                                </TableCell>
                                                <TableCell>{t('PlaybackView.measurements-table.description')}</TableCell>
                                                <TableCell align='right'>{t('PlaybackView.measurements-table.value')}</TableCell>
                                                <TableCell align='right'>MIN</TableCell>
                                                <TableCell align='right'>MAX</TableCell>
                                                <TableCell align='right'>AVG</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {angleData && measureKeys.map(key => {
                                                if (angleData.angles[frameNumber][key] === undefined)
                                                    return;

                                                return (
                                                    <TableRow key={key} style={{backgroundColor: enabledKeyPoints.includes(key) ? '#a7d5ff' : 'inherit'}}>
                                                        <TableCell className={classes.buttonsCell}>
                                                            {/*{!blockedAngles.includes(key) &&*/}
                                                            {/*<IconButton*/}
                                                            {/*    size='small'*/}
                                                            {/*    onClick={toggleAngle(key)}*/}
                                                            {/*>*/}
                                                            {/*    {state.enabledAngles[key] ? <VisibilityIcon fontSize='small' /> : <VisibilityOffIcon fontSize='small' />}*/}
                                                            {/*</IconButton>}*/}
                                                            {angleData && angleData.keyPoints[key].length > 0 && <IconButton
                                                                size='small'
                                                                onClick={toggleKeypoints(key)}
                                                            >
                                                                {enabledKeyPoints.includes(key) ? <LocationOffIcon fontSize='small'/> : <LocationOnIcon fontSize='small'/>}
                                                            </IconButton>}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {measureLabelsLocalized[language][key]}
                                                        </TableCell>
                                                        <TableCell align='right'>{angleData.angles[frameNumber][key].toFixed(2)}</TableCell>
                                                        <TableCell align='right'>{angleData.stats[key].min.toFixed(2)}</TableCell>
                                                        <TableCell align='right'>{angleData.stats[key].max.toFixed(2)}</TableCell>
                                                        <TableCell align='right'>{angleData.stats[key].avg.toFixed(2)}</TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                            <Paper
                                className={classes.measurements}
                            >
                                <Typography variant="h5" className={classes.measurementsTitle}>{t('PlaybackView.screenshots-table.title')}</Typography>
                                <TableContainer className={classes.tableContainer}>
                                    <Table stickyHeader className={classes.table} size="small" aria-label="sessions">
                                        <TableHead className={classes.sessionsTableHeader}>
                                            <TableRow>
                                                <TableCell>{t('PlaybackView.screenshots-table.date')}</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {screenshotTimes.map((row, i) => (
                                                <TableRow key={i}>
                                                    <TableCell component="th" scope="row">
                                                        <Moment format='HH:mm:ss.SSS'>{row.date}</Moment>
                                                    </TableCell>
                                                    <TableCell className={classes.buttonsCell}>
                                                        <IconButton
                                                            size='small'
                                                            onClick={() => {
                                                                setVideoCurrentTime(row.time);
                                                                sendMessageToSessionSocket('SET_PLAYBACK_TIME', row.time.toString());
                                                            }}
                                                        >
                                                            <VisibilityIcon/>
                                                        </IconButton>
                                                        <IconButton
                                                            size='small'
                                                            onClick={() => {
                                                                sendMessageToSessionSocket('DELETE_SCREENSHOT', row.time.toString());
                                                            }}
                                                        >
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </div>
                        <TimeLine
                            enabledKeyPoints={enabledKeyPoints}
                            keyPoints={angleData && angleData.keyPoints}
                            duration={videoRef.current.duration}
                            onKeyPointClick={(kp) => {
                                setVideoCurrentTime(kp);
                                sendMessageToSessionSocket('SET_PLAYBACK_TIME', kp.toString());
                            }}
                            onClick={time => {
                                setVideoCurrentTime(time);
                                sendMessageToSessionSocket('SET_PLAYBACK_TIME', time.toString());
                            }}
                            percentagePlayed={percentagePlayed}
                            timePlayed={videoRef.current ? currentTime : 0}
                        />
                        <div
                            className={classes.toolbar}
                        >
                            <span className={classes.timeDisplay}>
                                {fmtNum(hours, 2)}:{fmtNum(minutes, 2)}:{fmtNum(seconds, 2)}.{fmtNum(millis, 3)}
                            </span>
                            <Button
                                onClick={() => {
                                    setVideoCurrentTime(0);
                                    setPlaybackState(PAUSED);
                                    sendMessageToSessionSocket('SET_PLAYBACK_TIME', videoRef.current.currentTime.toString());
                                }}
                                className={classes.button}
                            >
                                <RestoreIcon/>
                            </Button>
                            <Button
                                onClick={togglePlayback(BACKWARD)}
                                className={clsx(classes.button, {[classes.buttonEnabled]: playbackState === BACKWARD})}
                            >
                                {playbackState === BACKWARD ? <PauseIcon/> : <PlayArrowIcon style={{transform: 'scaleX(-1)'}}/>}
                            </Button>
                            <Button
                                onClick={togglePlayback(FORWARD)}
                                className={clsx(classes.button, {[classes.buttonEnabled]: playbackState === FORWARD})}
                            >
                                {playbackState === FORWARD ? <PauseIcon/> : <PlayArrowIcon/>}
                            </Button>
                            <Button
                                onClick={() => setStopAtKeypoints(stop => !stop)}
                                className={clsx(classes.button, {[classes.buttonEnabled]: stopAtKeypoints})}
                            >
                                <PauseOnKeypointsIcon/>
                            </Button>
                            <Button
                                onClick={gotoPreviousKeyPoint}
                                className={classes.button}
                            >
                                <PreviousKeypointIcon/>
                            </Button>
                            <Button
                                onClick={gotoNextKeyPoint}
                                className={classes.button}
                            >
                                <NextKeypointIcon/>
                            </Button>
                            {[8, 4, 2].map(fraction => {
                                const speed = 1/fraction;
                                return (
                                    <Button
                                        key={fraction}
                                        onClick={() => setPlaybackSpeed(playbackSpeed === speed ? 1 : speed)}
                                        className={clsx(classes.button, classes.buttonSpeed, {[classes.buttonEnabled]: playbackSpeed === speed})}
                                    >
                                        <PlayArrowIcon/>
                                        <span className={classes.buttonSpeedFraction}>{`1/${fraction}`}</span>
                                    </Button>
                                )
                            })}
                            <Button
                                onClick={() => {
                                    sendMessageToSessionSocket('TAKE_SCREENSHOT');
                                }}
                                className={classes.button}
                            >
                                <AddAPhotoIcon/>
                            </Button>
                        </div>
                    </>
                }
            </div>
        </>
    );
}